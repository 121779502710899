import React from 'react'
import { 
    AlertDialog, 
    AlertDialogBody, 
    AlertDialogContent, 
    AlertDialogFooter, 
    AlertDialogHeader, 
    AlertDialogOverlay, 
    Button, 
} from '@chakra-ui/react'

export const JobDeleteDialogBox = ({isOpen, onClose, onNo, onYes, isDeleting}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>Delete this job</AlertDialogHeader>

                    <AlertDialogBody>
                        Do you want to delete this Job?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='yellow' onClick={onNo}>No</Button>
                        <Button isLoading={isDeleting} colorScheme='red' ml={3} onClick={onYes}>Yes</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
  )
}
