import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,useToast, Button } from '@chakra-ui/react'
import React from 'react'
import { PulseLoader } from 'react-spinners'

const DealsDeleteDialogBox = ({ isOpen, onClose, selectedFlatRows, isLoading, handleDeleteRows }) => {
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Delete Deals</AlertDialogHeader>
                <AlertDialogBody>Are you sure you want to delete the selected Deals</AlertDialogBody>
                <AlertDialogFooter>
                    <Button size={"sm"} onClick={onClose}>Cancel</Button>
                    <Button size={"sm"} colorScheme='red' isLoading={isLoading} spinner={<PulseLoader size={8} color='white' />} loadingText="Deleting" spinnerPlacement='end' onClick={() => handleDeleteRows(selectedFlatRows)} ml={3}>Delete</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default DealsDeleteDialogBox