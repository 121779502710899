import { createStore } from 'redux';

// // Initial state
// const initialState = {
//   role: null,
// };
// Initial state
// Initial state
const initialState = {
  role: null,
  userImg: null,
  userEmail: null,
  token: null,
  myFunction: null,
  isActive: 'Unread',
  selectedEmail: null,
  setIsActiveFunc: 'Unread', // To store setIsActive
  setSelectedEmailFunc: '', // To store setSelectedEmail
  setAllMailsFunc: [], // To store setAllMails function
};



const SET_ROLE = 'SET_ROLE';
const SET_USER_IMG = 'SET_USER_IMG';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const SET_TOKEN = 'SET_TOKEN';
const SET_FUNCTION = 'SET_FUNCTION';
const SET_IS_ACTIVE = 'SET_IS_ACTIVE'; // Added this line
const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL'; // Added this line
// Action types
const SET_IS_ACTIVE_FUNC = 'SET_IS_ACTIVE_FUNC';
const SET_SELECTED_EMAIL_FUNC = 'SET_SELECTED_EMAIL_FUNC';
const SET_ALL_MAILS_FUNC = 'SET_ALL_MAILS_FUNC'; // New action type


// Action creators
export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setRole = (role) => ({
  type: SET_ROLE,
  payload: role,
});

export const setUserImg = (userImg) => ({
  type: SET_USER_IMG,
  payload: userImg,
});

export const setUserEmail = (email) => ({
  type: SET_USER_EMAIL,
  payload: email,
});

export const setFunction = (token, filter) => ({
  type: SET_FUNCTION,
  payload:{ token, filter },
});
// Action creators
export const setIsActiveFunc = (isActive) => ({
  type: SET_IS_ACTIVE_FUNC,
  payload: isActive,
});

export const setSelectedEmailFunc = (slectedmail) => ({
  type: SET_SELECTED_EMAIL_FUNC,
  payload: slectedmail,
});
export const setAllMailsFunc = (allmail) => ({ // New action creator
  type: SET_ALL_MAILS_FUNC,
  payload: allmail,
});


// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLE:
      return { ...state, role: action.payload };
    case SET_USER_IMG:
      return { ...state, userImg: action.payload };
    case SET_USER_EMAIL:
      return { ...state, userEmail: action.payload };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_FUNCTION:
      return { ...state, myFunction: action.payload };
      case SET_IS_ACTIVE: 
      return { ...state, isActive: action.payload };
    case SET_SELECTED_EMAIL: 
      return { ...state, selectedEmail: action.payload };
      case SET_ALL_MAILS_FUNC: 
      return { ...state, setAllMailsFunc: action.payload };
    default:
      return state;
  }
};


// Create store
const store = createStore(reducer);

export default store;

