import React, { useEffect, useMemo, useState } from "react";
import { USERS_COLUMNS } from "./Columns";
import { useTable, usePagination, useRowSelect } from "react-table";
import {
  Box,
  Button,
  Flex,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { HiArrowDown, HiArrowUp } from "react-icons/hi";
import { BsPersonPlusFill } from "react-icons/bs";
import UserFormModal from "../Modal/UserFormModal";
import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import UserDeleteDialogBox from "../Modal/UserDeleteDialogBox";
import UserPasswordChangeDialogBox from "../Modal/UserPasswordChangeDialogBox";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { setUserImg } from "../../store/store";
import { useDispatch } from "react-redux";
import { fetchInfluencerMetaData } from "../../services/influencerApi";


const UserTable = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const columns = useMemo(() => USERS_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  console.log("pagecount",pageCount);
  
  const [usersData, setUsersData] = useState([]);
  const [isOpenUserFormModal, setIsOpenUserFormModal] = useState(false);
  const [isOpenUserDeleteDialogBox, setIsOpenUserDeleteDialogBox] = useState(false);
  const [isOpenPasswordChangeDialogBox, setIsOpenPasswordChangeDialogBox] =useState(false);
  const [ispasswordChange, setIspasswordChange] = useState(false);
  const [password, setPassword] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [influencers,setInfluencers] = useState([])
  const dispatch =useDispatch()
  const userEmail = useSelector((state) => state.userEmail) || localStorage.getItem('userEmail');
  const userImg = useSelector((state) => state.userImg);
console.log('userEmail',userEmail);


  const handleClick = () => setShowPassword(!showPassword);

  const [errors, setErrors] = useState({
    password: "",
  });

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: usersData,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount,
    },
    // useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );


   useEffect(()=>{
    // Find the object where email matches userEmail
    console.log('useremail',userEmail);
    
const userObject = usersData.find(user => user.email === userEmail);
console.log('userobj',userObject)
 if(userObject){
  dispatch(setUserImg(userObject.profile_picture_url));
  localStorage.setItem('userImg',userObject.profile_picture_url );

 }

   },[usersData])

  const fetchUserData = async () => {
    try {
      const response = await fetch(BASE_URL + `/get-all-users/?page=${pageIndex + 1}&page_size=${pageSize}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = '/login';
      }

      setUsersData(data?.data);

      console.log('userdtaa>',data?.data);
      
      
      setPageCount(data?.paginator?.total_pages);
      console.log("data",data);
      
      setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);

      toast({
        title: "Fetch Error.",
        description: "Failed to fetch users details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      await fetchUserData();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pageIndex, pageSize]);

  const handleDeleteUser = async (selectedFlatRows) => {
    try {
      setIsLoading(true);
      let allEmails=[];
      const deletePromise = async () => {
        selectedFlatRows.forEach(row=>{
            allEmails.push(row?.original?.email)
        })
        

        const response = await fetch(BASE_URL + "/delete-users/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ emails: allEmails }),
        });

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = '/login';
        }
        if (response.ok) {
          // Do your success handling here
          return { success: true, allEmails };
        } else {
          // Do your error handling here
          return { success: false, allEmails };
        }
      };

      const results = [await deletePromise()];

      results?.forEach((result) => {
        const { success, allEmails } = result;

        if (success) {
          // Handle success
          allEmails.forEach(email=>{
            setUsersData((prevData) =>
            prevData.filter((user) => user.userEmail !== email)
          );
          })

          
          fetchUserData();
          toast({
            title: "User Deleted",
            description: "User deleted successfully.",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          setIsOpenUserDeleteDialogBox(false);
        } else {
          // Handle error
          toast({
            title: "Deletion Failed",
            description: "Failed to delete User details.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete User details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  const handleChangePassword = async (selectedFlatRows, password) => {
    try {
      setIsLoading(true);

      if (!password) {
        setErrors((prev) => ({
          ...prev,
          password: "New Password is required.",
        }));
        setIsLoading(false);
        return;
      }

      const deletePromises = selectedFlatRows.map(async (row) => {
        const userEmail = row?.original?.email;
        const firstName = row?.original?.first_name;

        const response = await fetch(BASE_URL + "/change-password/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: userEmail,
            first_name: firstName,
            new_password: password,
          }),
        });

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = '/login';
        }
        if (response.ok) {
          // Do your success handling here
          return { success: true, userEmail };
        } else {
          // Do your error handling here
          return { success: false, userEmail };
        }
      });

      const results = await Promise.all(deletePromises);

      results?.forEach((result) => {
        const { success, userEmail } = result;

        if (success) {
          // Handle success
          setUsersData((prevData) =>
            prevData.filter((user) => user.userEmail !== userEmail)
          );
          fetchUserData();
          toast({
            title: "Password Changed",
            description: "User Password Changed successfully.",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          setIsOpenPasswordChangeDialogBox(false);
          setPassword("");
        } else {
          // Handle error
          toast({
            title: "Password Change Failed",
            description: "Failed to change User password.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      });
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete User details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // handleEditRow()
    setIsEditing(null);
    setEditedRowData(null);
  }, []);

  //   .................................

  useEffect(() => {
    if (selectedFlatRows.length > 1) {
      setIspasswordChange(false);
    } else {
      setIspasswordChange(true);
    }
  }, [selectedFlatRows]);

  // .....................

  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
      handleSaveRow();
    }
  };

  const handleCellClick = (row, cell) => {
    if (cell.column) {
      setIsEditing(row.original.id);
      setEditedRowData(row.original);
      setEditingCell(cell.column.id);
    }
  };

  const handleEditRow = async () => {
    setIsEditing(selectedFlatRows[0]?.original?.id);
    setEditedRowData(selectedFlatRows[0]?.original);
  };

  const handleSaveRow = async () => {
    try {
      const response = await fetch(BASE_URL + "/update-users/", {
        method: "POST",
        body: JSON.stringify({ user_data: [editedRowData] }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        fetchUserData();
         

        toast({
          title: "User Updated",
          description: "User updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Update Failed",
          description: "Failed to update user details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update user details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };


     const fetchinfluencer = async () =>{
      try{
        const Influencers = await fetchInfluencerMetaData(token);
        console.log('influencer123',Influencers);
        setInfluencers(Influencers);
        
      }
         catch (error) {
                console.log('error',error);
                
         }

     }

      useEffect(() => {
        
        fetchinfluencer();

      }, []);

  return (
    <Box px={5} py={3}>
      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              {isEditing && (
                <Button onClick={handleSaveRow} size={"sm"} colorScheme="blue">
                  Save
                </Button>
              )}
              {!isEditing && (
                // <Box onClick={() => setIsOpenUserDeleteDialogBox(true)} bg={"none"} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}><IoMdTrash size={20} color='red' /></Box>
                <Button
                  onClick={() => setIsOpenUserDeleteDialogBox(true)}
                  size={"sm"}
                  colorScheme="red"
                  variant="solid"
                >
                  <IoMdTrash size={20} color="white" />
                </Button>
              )}
              {!isEditing && ispasswordChange && (
                // <Box onClick={() => setIsOpenPasswordChangeDialogBox(true)} bg={"none"} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}><IoMdTrash size={20} color='red' /></Box>
                <Button
                  onClick={() => setIsOpenPasswordChangeDialogBox(true)}
                  size={"sm"}
                  colorScheme="red"
                  variant="solid"
                >
                  <RiLockPasswordFill size={20} color="white" />
                </Button>
              )}
            </Flex>
          </Flex>
        ) : (
          <Box
            sx={{
              textAlign: "right",
            }}
            mb={3}
          >
            <Button
              onClick={() => setIsOpenUserFormModal(true)}
              size={"sm"}
              colorScheme="blue"
              variant="solid"
            >
              <BsPersonPlusFill size={20} color="white" />
            </Button>
          </Box>
        )}
        <Box
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(100vh - 180px)"}
          w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{
            position: "relative",
          }}
        >
          <TableContainer
            //   onClick={() => handleCellBlur()} // Handle clicks outside the table
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"}   className='fix-header'>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                          <Box>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <HiArrowDown />
                              ) : (
                                <HiArrowUp />
                              )
                            ) : (
                              ""
                            )}
                          </Box>
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const isRowEditing = isEditing === row?.original?.id;

                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;
                        return (
                          <Td
                            {...cell.getCellProps()}
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                          >
                            {cell.column.id === "selection" ? (
                              <Checkbox
                                {...row.getToggleRowSelectedProps()}
                                disabled={isRowEditing}
                              />
                            ) : cell.column.Header === "Created At" ? (
                              format(
                                new Date(row.original.created_ts),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            ) : // cell.column.id === 'partners_domain_name' ? (
                            //     cell.render('Cell')
                            // ) :
                            // cell.column.id === 'whitelisted_company_ids' ? (
                            //     cell.render('Cell')
                            // ) :
                            cell.column.id === "blacklisted_company_ids" ? (
                              cell.render("Cell")
                            ) : isCellEditing ? (
                              <Textarea
                                style={{ resize: "both", minWidth: "150px" }}
                                // w="250px"
                                type="text"
                                value={row.values[cell.column.id]}
                                onChange={(e) => {
                                  const fieldName = cell.column.id;
                                  const value = e.target.value;
                                  if (
                                    fieldName === "meta_tags" ||
                                    fieldName ===
                                      "whitelisted_company_domains" ||
                                    fieldName === "partners_domain_name"
                                  ) {
                                    const metaTagsArray = value
                                      .split(",")
                                      .map((tag) => tag);
                                    row.values[fieldName] = metaTagsArray;
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: metaTagsArray,
                                    });
                                  } else {
                                    row.values[fieldName] = value;
                                    setEditedRowData({
                                      ...editedRowData,
                                      [fieldName]: value,
                                    });
                                  }
                                }}
                                onBlur={() => handleCellBlur()} // Auto-save on input blur
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex>
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Flex>
          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft
                size={24}
                onClick={() => previousPage()}
                disabled={pageIndex === 0}
              />
            </Box>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronRight
                size={24}
                onClick={() => nextPage()}
                disabled={pageIndex === pageCount - 1}
              />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>

      <UserFormModal
        isOpen={isOpenUserFormModal}
        influencers={influencers}
        onClose={() => {
          setIsOpenUserFormModal(false);
        }}
        fetchUserData={fetchUserData}
      />

      <UserDeleteDialogBox
        isOpen={isOpenUserDeleteDialogBox}
        onClose={() => {
          setIsOpenUserDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteUser={handleDeleteUser}
      />
      <UserPasswordChangeDialogBox
        isOpen={isOpenPasswordChangeDialogBox}
        onClose={() => {
          setIsOpenPasswordChangeDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleChangePassword={handleChangePassword}
        password={password}
        setPassword={setPassword}
        errors={errors}
        showPassword={showPassword}
        handleClick={handleClick}
      />
    </Box>
  );
};

export default UserTable;
