import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    useColorMode,
    Flex
  } from '@chakra-ui/react'
  import Markdown from 'react-markdown';
  import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { BiLeftArrow } from 'react-icons/bi';
import "../Table/PastPreview.css"
import { Divider } from '@chakra-ui/react'
const PreviousModal = ({isOpen,onClose,ModalResponse,FileName}) => {
    const [scrollBehavior, setScrollBehavior] = React.useState('inside')
    const {colorMode}=useColorMode()

 return (
    <>

      <Modal  size={'4xl'} isOpen={isOpen} onClose={onClose}    scrollBehavior={scrollBehavior}>
      
        <ModalOverlay />
       
        <ModalContent>
          <ModalHeader  className='modal-header'>
            <Flex>
               <Box  className='arrow-lefts' _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", 
                      cursor: "pointer", borderRadius:     "100vh" }} p={"7px"}>
                <GoChevronLeft size={24} onClick={onClose} className='arrow-pre' 
              />
              </Box>
              {/* { FileName.replace(/\.(pdf|doc|docx)+$/gi, "") } */}
              { FileName.split('.')[0].replace(/(\.[^/.]+)+$/, "")  }
                 

            </Flex>
            <Divider />
            </ModalHeader>
          <ModalBody>
            <Markdown>
              { ModalResponse &&  ModalResponse
              .replace(/\n/g, '\n\n')
              .replace(/\n\*\*Question/g, '\n---\n**Question')
              .replace(/(Question \d+:.*?)\?/g, '**$1?**')
               
               }
            </Markdown>
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
 )
}

export default PreviousModal
