import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Box,
  InputGroup,
  FormErrorMessage,
  Input,
  FormControl,
  FormLabel,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { PulseLoader } from "react-spinners";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const UserPasswordChangeDialogBox = ({
  isOpen,
  onClose,
  selectedFlatRows,
  isLoading,
  handleChangePassword,
  password,
  setPassword,
  errors,
  showPassword,
  handleClick,
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Change Password
          </AlertDialogHeader>
          <AlertDialogBody>
            <Box  gap={5} mb={10}>
            {selectedFlatRows[0]?.original?.email}
            </Box>
            <Box  gap={5} mb={10}>
              <FormControl isRequired isInvalid={!!errors.password}>
                <FormLabel
                  fontSize="sm"
                  position="absolute"
                  top="-3"
                  left="3"
                  zIndex="1"
                  background="#ffffff"
                  px="1"
                >
                  Password
                </FormLabel>
                <InputGroup>
                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    maxLength={20}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    _focus={{ zIndex: "0" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {showPassword ?  <ViewOffIcon></ViewOffIcon> : <ViewIcon></ViewIcon>}
                    </Button>
                  </InputRightElement>
                  </InputGroup>
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>
              </Box>
            
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button size={"sm"} onClick={onClose}>
              Cancel
            </Button>
            <Button
              size={"sm"}
              colorScheme="red"
              isLoading={isLoading}
              spinner={<PulseLoader size={8} color="white" />}
              loadingText="Submitting"
              spinnerPlacement="end"
              onClick={() => handleChangePassword(selectedFlatRows, password)}
              ml={3}
            >
              Submit
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default UserPasswordChangeDialogBox;
