import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { EmailStatus } from "../campaigns/EmailStatus";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export const EmailStatusModal = ({ isOpen, onClose, campaign }) => {
  const [statusData, setStatusData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const toast  = useToast();
  const navigate = useNavigate()


  const fetchData = async () => {
    setIsLoading(true)

    const payload = {
        status_id : campaign.email_schedule,
        campaign_id : campaign.campaign_id
    }
    try {
      const response = await fetch(BASE_URL + `/status/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
  
      if(data.code === 'token_not_valid'){  
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      };
      
      setStatusData(data);
      setIsLoading(false);
    } catch (error) {
      toast({
        title: "Fetch Error.",
        description: "Failed to fetch company details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };
  
  useEffect(()=>{
    fetchData()
  },[campaign]);


  return (
    <Modal size={"6xl"}  isOpen={isOpen} onClose={onClose} isCentered  scrollBehavior={'inside'}>
      <ModalOverlay />
      <ModalContent pb={3}>
        <ModalHeader fontWeight={"500"} fontSize={"2xl"}>
          Email Scheduled
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
            {/* <Text>EmailSchedule Id: {statusId}</Text> */}

            {
                (statusData)?
                <Skeleton isLoaded={!isLoading}>
                    <EmailStatus campaign_id={campaign.campaign_id} status_id= {campaign.email_schedule} statusData={statusData} fetchData = {fetchData} /> 
                </Skeleton> :
                <Skeleton height='100px'/>
            }
            
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
