import React, { useEffect, useMemo, useState } from 'react'
import { PROSPECT_COLUMNS } from './Columns';
import { useTable, useRowSelect } from 'react-table';
import { Box, Button, Flex, Input, Table, TableContainer, Tbody, Td, Textarea, Th, Thead, Tr, useColorMode, useToast } from '@chakra-ui/react';
import Checkbox from './Checkbox';
import { IoMdTrash } from 'react-icons/io';
import { format } from "date-fns";
import { AddIcon } from '@chakra-ui/icons';
import { TbMailSearch } from "react-icons/tb";
import ProspectFormModal from '../Modal/ProspectFormModal';
import { CreateCampaignModal } from '../Modal/CreateCampaignModal';
// import { fetchInfluencerMetaData } from '../../services/influencerApi';
// import { fetchAllSequences } from '../../services/sequenceApi';
// import { fetchUserEmails } from '../../services/userEmailsApi';
import ProspectDeleteDialogBox from '../Modal/ProspectDeleteDialogBox';
// import EmailsFormModel from '../Modal/EmailsTableModel';
import EmailsModel from '../Modal/EmailsModel';
import { BsFillSendPlusFill } from "react-icons/bs";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const ProspectTableModal = ({ sequences, influencers, userEmails, prospectData, companyID, companyName, fetchCompanyData, handleRowClick, setUpdateProspect, onCloseProspect, setIsOpenProspectModal, updateProspect, setTargetProspectId, setUpdateEmail, updateEmail, setProspectDelete, setProspectData }) => {

  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')


  const toast = useToast();
  const { colorMode } = useColorMode();
  const navigate = useNavigate()


  const columns = useMemo(() => PROSPECT_COLUMNS, []);
  const [data, setData] = useState(prospectData);

  // console.log("DATA",data[0])


  console.log("updateProspectttt", updateProspect)
  // const deletedData =data;
  // console.log("deleted dataattatatat",deletedData)
  const [prospectUpDelete, setProspectUpDelete] = useState(false);
  //    console.log("prospectUpDelete66666666666666",prospectUpDelete)

  const [isOpenProspectFormModal, setIsOpenProspectFormModal] = useState(false);
  // const [isOpenEmailsFormModel, setIsOpenEmialsFormModel] = useState(false);
  const [isOpenProspectDeleteDialogBox, setIsOpenProspectDeleteDialogBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [editedRowData, setEditedRowData] = useState(null);
  const [flat, setFlat] = useState(null);
  const [Final, setFinal] = useState([]);

  const [verifyemails, setVerifyEmails] = useState([]);
  console.log("verifyEmails>>>", verifyemails)


  //  console.log("prospectDataANoop",prospectData)
  // console.log("prospectData",prospectData)


  const [isOpenEmailsModel, setIsOpenEmailsModel] = useState(false);
  // const [prospectsData, setProspectsData] = useState(null);





  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    selectedFlatRows,
    toggleRowSelected,
  } = useTable({
    columns,
    data,
  },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox  {...row.getToggleRowSelectedProps()} />
            )
          },
          ...columns
        ]
      })
    });

  console.log("selectedFlatRows", selectedFlatRows)


  // console.log("prooooo id ",prospectData[0].prospect_id)



  const handleDeleteRows = async (selectedFlatRows) => {
    // setUpdateProspect(false)
   console.log('hello pmodal in modal dialog')
    try {
      setIsLoading(true);
      const prospectIds = selectedFlatRows.map(row => row?.original?.prospect_id);


      const response = await fetch(BASE_URL + '/prospect/', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ prospect_ids: prospectIds })
      });

      if (response.status === 401) {
        console.error('Unauthorized: User session expired');
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
      };

      if (response.ok) {
        setIsLoading(false);
        setData(prevData => prevData.filter(prospect => !prospectIds.includes(prospect.prospect_id)));
        setProspectData(prevData => prevData.filter(prospect => !prospectIds.includes(prospect.prospect_id)));


        // setProspectUpDelete(true)
        setProspectDelete(true)
        toast({
          title: 'Prospects  Deleted',
          description: 'Prospects  deleted successfully.',
          status: 'success',
          position: 'bottom',
          duration: 3000,
          isClosable: true,
        });

        setIsOpenProspectDeleteDialogBox(false);
        fetchCompanyData();

      }
      else {
        setIsLoading(false);

        toast({
          title: 'Deletion Failed',
          description: 'Failed to delete prospects details.',
          status: 'error',
          position: 'bottom',
          duration: 3000,
          isClosable: true,
        })
      }
    }
    catch (error) {
      toast({
        title: 'Deletion Failed',
        description: 'Failed to delete prospects details.',
        status: 'error',
        position: 'bottom',
        duration: 3000,
        isClosable: true,
      })
      console.error('Error:', error);
    }

  };


  const handleEditRow = () => {
    const selectedEditableProspects = selectedFlatRows?.map((row) => row.original.prospect_id);
    setIsEditing(selectedEditableProspects);
    // setIsEditing(selectedFlatRows[0]?.original?.prospect_id);
    // setEditedRowData(selectedFlatRows[0]?.original);
    const selectedRowData = selectedFlatRows?.map((row) => row.original);
    setEditedRowData(selectedRowData);
  };
  // console.log("isEditable>>",editedRowData)



  const handleVerifyEmails = async () => {
    //  console.log("flat verify id >>",selectedFlatRows[0]?.original.prospect_id)
    const payload = {
      "prospect_id": selectedFlatRows[0]?.original.prospect_id,

    }
    try {
      const response = await axios.post(`${BASE_URL}/show-verify-email/`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      //  console.log("responseee>>",response)
      if (response.data.length > 0) {
        setVerifyEmails(response.data)
      }
      else {
        setVerifyEmails([])
      }

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
      };




    }
    catch (error) {
      console.error("error", error)
    }
  }



  const hadnleClickEmails = () => {
    setUpdateEmail(false)
    // console.log("selectedFlatRows[0]",selectedFlatRows[0])
    //  console.log("selectedflatrow",selectedFlatRows)
    //  console.log("@vishal SImran  ",selectedFlatRows[0]?.original)
    setIsOpenEmailsModel(true);
    console.log("selectedFlatrowsfrosearchbutton", selectedFlatRows)
    setFlat(selectedFlatRows[0]?.original.prospect_id)

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      "prospects_data": [
        {
          "first_name": selectedFlatRows[0]?.original.first_name,
          "last_name": selectedFlatRows[0]?.original.last_name,
          "company": selectedFlatRows[0]?.original.company,


        }
      ]
    });


    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${BASE_URL}/prospect-emails/`, requestOptions)

      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate('/login');
        }
        return response.json();
      })
      .then((result) => {

        setFinal(result);
        handleVerifyEmails();



      })
      .catch((error) => console.error(error));
  }


  //   if(isOpenProspectFormModal){
  //     setUpdateProspect(false)
  //   }




  useEffect(() => {
    // handleEditRow()
    setIsEditing(null)
    setEditedRowData(null)

  }, [selectedFlatRows])


  useEffect(() => {

    // console.log("call me don @ simran ")
    //console.log("call me donprospectDara>>>>",prospectData)

    //     prospectUpDelete ?
    //     setData(deletedData) :

    if (updateProspect || !isOpenProspectFormModal) {
      setData(prospectData)
      fetchCompanyData();
    }

  }
    , [updateProspect, !isOpenProspectFormModal])


  useEffect(() => {
    if (!updateProspect) {
      // console.log("call me don @ simran Sir")
      // console.log("call me donprospectDara>>>>",prospectData)
      setData(prospectData)
      fetchCompanyData();
    }
  }, [!updateProspect])

  useEffect(() => {
    if (!isOpenEmailsModel) {
      //console.log("call me don again")
      // console.log("call me donprospectDara again >>>>",prospectData)


      setData(prospectData)
      fetchCompanyData();

    }
  }, [!isOpenEmailsModel])


  useEffect(() => {
    if (isOpenEmailsModel) {
      // console.log("call me don @ simran ")
      //console.log("call me donprospectDara>>>>",prospectData)

      //    setProspectUpDelete(true)
      fetchCompanyData();


    }
  }, [isOpenEmailsModel])



  const handleCreateCampaignClick = () => {
    setIsCreateCampaignModalOpen(true);
  };



  const handleSaveRow = async () => {
    // const prospectId = editedRowData?.prospect_id;
    const payload = {
      prospect_data: editedRowData
    }
    //    //prospects/${prospectId}/
    try {
      const response = await fetch(BASE_URL + `/updateprospects/`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
      }
      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);

        toast({
          title: "Prospect Updated",
          description: "Prospect updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        // Now, uncheck all selected checkboxes
        rows.forEach(row => {
          toggleRowSelected(row.id, false);
        });

      } else {
        throw new Error('Failed to update prospect details.');
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update prospect details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };
  //   console.log("flat.....",flat)
  // console.log("flat",flat)
  // console.log("final",Final)
  const handleProspectClick = () => {
    setIsOpenProspectFormModal(true);
    // setProspectUpDelete(true);

    setUpdateProspect(false)
    fetchCompanyData();
  };

  return (

    <Box>
      <EmailsModel
        isOpen={isOpenEmailsModel}
        onClose={() => {
          setIsOpenEmailsModel(false);
          //  setFlat(null)
          //  setFinal([])
          fetchCompanyData();

          //  fetchCompanyData();
        }}
        EmailsData={Final}
        prospect_id={flat}
        prospectData={prospectData}
        verifyemails={verifyemails}
        handleVerifyEmails={handleVerifyEmails}
        hadnleClickEmails={hadnleClickEmails}
        fetchCompanyData={fetchCompanyData}
        handleRowClick={handleRowClick}


        setData={setData}
        setUpdateProspect={setUpdateProspect}
        setTargetProspectId={setTargetProspectId}
        setUpdateEmail={setUpdateEmail}
        onCloseProspect={onCloseProspect}
      //    selectedPropspectsTable={selectedFlatRows}


      //    SaveData= {fetchProspectData}
      />
      {
        selectedFlatRows.length > 0 ?
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              <Button
                onClick={handleCreateCampaignClick}
                size={"sm"}
                colorScheme="blue"
                variant="solid"
              >
                <BsFillSendPlusFill size={20} color="white" />
              </Button>

              {selectedFlatRows.length === 1 ? (
                <Button

                  onClick={hadnleClickEmails}
                  size={"sm"}
                  colorScheme="blue"
                  variant="solid"
                >
                  <TbMailSearch size={25} color="white" />
                </Button>
              ) : " "}
              {
                isEditing !== null ?
                  <Button onClick={handleSaveRow} size={"sm"} colorScheme='blue'>Save</Button> :
                  <Button onClick={handleEditRow} size={"sm"} colorScheme='yellow'>Edit</Button>


              }
              <Button
                // onClick={() => handleDeleteRows(selectedFlatRows)} 
                onClick={() => setIsOpenProspectDeleteDialogBox(true)} colorScheme="red" variant="solid"
              ><IoMdTrash size={20} color="white" /></Button>
            </Flex>
          </Flex> :


          <Button mb={3} onClick={handleProspectClick} size={"sm"} leftIcon={<AddIcon />} colorScheme='blue' variant='solid'>PROSPECT</Button>

      }
      <Box fontSize={14} mb={1} boxShadow='xs' h={"400px"} rounded={"md"} sx={{
        position: 'relative'
      }}>
        <TableContainer {...getTableProps()} sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'scroll',
          "&::-webkit-scrollbar": { height: "10px", width: "0px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: "10px" },
          "&::-webkit-scrollbar-thumb": { background: "#3182ce", borderRadius: "10px" },
        }}>
          <Table variant='simple' width={"100%"}>
            <Thead bg={"green.400"}>
              {headerGroups?.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                      <Flex alignItems={"center"}>
                        {column.render('Header')}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows?.map(row => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} _hover={{ bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" }}>
                    {row.cells.map(cell => {
                      // const isRowEditing = isEditing === row?.original?.prospect_id;
                      const isRowEditing = isEditing?.includes(row?.original?.prospect_id);
                      return (
                        <Td {...cell.getCellProps()}>
                          {
                            cell.column.id === 'selection' ? (
                              <Checkbox {...row.getToggleRowSelectedProps()} />
                            ) : cell.column.Header === 'Created At' ? (
                              format(new Date(row.original.created_ts), 'MM/dd/yyyy HH:mm:ss')
                            ) : (
                              isRowEditing ? (
                                <Textarea
                                  style={{ resize: "both", minWidth: "250px" }}
                                  // w="250px"
                                  type="text"
                                  value={row.values[cell.column.id]}
                                  // onChange={(e) => {
                                  //     const fieldName = cell.column.id;
                                  //     row.values[fieldName] = e.target.value;
                                  //     setEditedRowData({ ...editedRowData, [fieldName]: e.target.value });
                                  // }}
                                  onChange={(e) => {
                                    const fieldName = cell.column.id;
                                    const updatedValue = e.target.value;
                                    const updatedProspectId = row.original.prospect_id;
                                    row.values[fieldName] = updatedValue;
                                    // Create a new array with updated data for the corresponding prospect
                                    const updatedEditedRowData = editedRowData?.map((rowData) => {
                                      if (rowData.prospect_id === updatedProspectId) {
                                        return {
                                          ...rowData,
                                          [fieldName]: updatedValue,
                                        };
                                      }
                                      return rowData;
                                    });

                                    setEditedRowData(updatedEditedRowData);
                                  }}
                                />
                              ) : (
                                cell.render('Cell')
                              )
                            )
                          }
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <CreateCampaignModal
        sequences={sequences}
        emailList={userEmails}
        influencers={influencers}
        selectedPropspects={selectedFlatRows}
        isOpen={isCreateCampaignModalOpen}
        setIsCreateCampaignModalOpen={setIsCreateCampaignModalOpen}

      // onClose={() => {
      // setIsCreateCampaignModalOpen(false);
      // }}
      />

      <ProspectFormModal
        isOpen={isOpenProspectFormModal}
        onClose={() => {
          setIsOpenProspectFormModal(false);
          // setProspectUpDelete(true)
          // fetchCompanyData();
        }}
        companyID={companyID}
        companyName={companyName}
        setUpdateProspect={setUpdateProspect}
        fetchCompanyData={fetchCompanyData}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        onCloseProspect={onCloseProspect}
        setIsOpenProspectModal={setIsOpenProspectModal}
        setTargetProspectId={setTargetProspectId}
        setProspectUpDelete={setProspectUpDelete}
        prospectDataform={prospectData}
        setData={setData}
        setProspectData={setProspectData}
      />
      <ProspectDeleteDialogBox
        isOpen={isOpenProspectDeleteDialogBox}
        onClose={() => {
          setIsOpenProspectDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />

    </Box>
  )
}

export default ProspectTableModal