import { Toast, createStandaloneToast } from "@chakra-ui/react";

const toast = createStandaloneToast();

const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
const token = localStorage.getItem('token');

// Function to fetch all sequences
export const fetchUserEmails = async (token) => {
  const url = `${BASE_URL}/get-user-emails/`; // Adjust the endpoint URL
  try {
    const response = await fetch(url,{
      headers: {
        'Authorization': `Bearer ${token}`
      }});
    if (!response.ok) {
      throw new Error("Failed to fetch emails");
    }
    const data = await response.json();
    return data.User_Emails;
  } catch (error) {
    toast({
      title: "Error",
      description: "Failed to fetch Emails",
      status: "error",
      position: "bottom",
      duration: 3000,
      isClosable: true,
    });
    throw error;
  }
};
