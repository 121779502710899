import React from 'react'

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Input,
    Stack,
    Flex,
    border,
   Link
    
  } from '@chakra-ui/react'
  import { useState ,useEffect} from 'react'
  import { format, parse, isValid, setDate ,parseISO} from "date-fns";
  import "react-datepicker/dist/react-datepicker.css";
import "../Table/datepicker.css";
import { Text,InputGroup,InputLeftAddon } from '@chakra-ui/react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    useToast
  } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/react';
import { logDOM } from '@testing-library/react';
import axios from 'axios'
import { reduceHooks } from 'react-table';

const replaceWordWithLink = (text, word, url) => {
  const regex = new RegExp(`(${word})`, 'gi');
  console.log("Regular Expression:", regex);
  const result = text.replace(regex, `<a href="${url}" target="_blank" rel="noopener noreferrer">$1</a>`);
  console.log("Resulting Text:", result);
  return result;
};



const DealsFollowUpModel = ({isOpen,onClose,talent_name,rowData,onhandleState,fetchinfluencerDealsData,FollowupEmails}) => {

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const live_link =rowData?.original?.live_links

console.log('rowww from follow up >>>',live_link);
     const talent =talent_name.replace(/-/g, ' ')
   const [date,setDate] =useState("")
   const [newDate,setNewDate] = useState("")
   console.log("date",date);
   console.log("newdate",newDate)
   const [url, setUrl] = useState(live_link); // Dynamic URL state
   const[emails,setEmails] = useState("")

   const [hyperlinkedText, setHyperlinkedText] = useState('');
  
    const  [user,SetUser] =useState("")
    const[subject,setSubject] = useState(`${talent} Would Love To Collobrate Again`)
    const [firstName,setFirstName] =useState(`Hi`)
    

    const [text,setText]=useState(`We hope all is well. ${talent} really enjoyed collaborating with you and asked me to reconnect with you to explore working together again. Is this something you would be interested in? Looking forward to hearing from you.\n\nThanks, `)
    const[text1,settext1] =useState("")

  //   const [text, setText] = useState(
  //     <>
  //         We hope all is well. {talent} really enjoyed{' '}
  //         <Link href="http://your-link-here" target="_blank" rel="noopener noreferrer">
  //             collaborating
  //         </Link>{' '}
  //         with you and asked me to reconnect with you to explore working together again. Is this something you would be interested in? Looking forward to hearing from you.
  //         <br/><br/>
  //         Thanks,
  //     </>
  // );
    const[AdjustedDate,setAdjustedDate] =useState("")
    const [btn,setBtn] =useState(false)
    const [finalData ,setFinalData]=useState()
    const[fromMail,setFromMail]=useState()
    const[DealsIcon,SetDealsIcon]=useState(false)

   const toast = useToast()

//    const replaceWordWithLink = (text, word, url) => {
//     // Create the regular expression
//     const regex = new RegExp(`(${word})`, 'gi');
    
//     // Log the regular expression to the console
//     console.log("Regular Expression:", regex);
    
//     // Replace the word with a hyperlink
//     const result = text.replace(regex, `<Link href="${url}" target="_blank" rel="noopener noreferrer">$1</Link>`);
    
//     // Log the resulting text to the console
//     console.log("Resulting Text:", result);
//     settext1(result)
//     return result;
// };




  // Update hyperlinkedText whenever text or URL changes
  useEffect(() => {
    const linkedText = replaceWordWithLink(text, 'collaborating', url);
    setHyperlinkedText(linkedText);
}, [text, url]);



 useEffect(()=>{
setUrl(live_link)

 },[url,live_link])
    const handleDateChange=(e)=>{


    setDate(e.target.value)
    console.log(e.target.value,"original valu of date")
    const originalDate = new Date(e.target.value);
    console.log(originalDate, "original value of date  after");

    // Add 2 minutes to the original date
    // originalDate.setMinutes(originalDate.getMinutes() + 2);


  



    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    const hours = String(originalDate.getHours()).padStart(2, '0');
    const minutes = String(originalDate.getMinutes()).padStart(2, '0');
    const adjustedDatee = `${year}-${month}-${day}T${hours}:${minutes}`;

    console.log(adjustedDatee, "adjusted value of date");

    setAdjustedDate(adjustedDatee)

    


    }

    const isValidISODate = (date) => {
        const parsedDate = parseISO(date);
        return isValid(parsedDate);
      };
    
      const parseFormattedDate = (date) => {
        return parseISO(date);
      };
    
      const formattedDate = () => {
        const parsedDate = parseFormattedDate(date);
        const formattedDateString = date && isValidISODate(date) && parsedDate 
          ? format(parsedDate,  'MM/dd/yyyy HH:mm a') 
          : '--------';
        setNewDate(formattedDateString);
      };

      useEffect(()=>{

         formattedDate()
      },[date])



      const handleFollowUpOffer = async()=>{
        const object = {
         brand_agency: rowData?.original?.brand_agency,
         brand_rep: rowData?.original?.brand_rep
        }
         try {
             const result = await axios.post(`${BASE_URL}/prospect-email/`,object,{
                 headers:{
                     Authorization : `Bearer ${token}`
                 }
             })

             console.log("result>>>",result)
             if(result.status === 200){
              setFinalData(result.data)
             
            //  onClose();
         }
             
         } catch (error) {
             console.log("error>>>",error)
            //  toast({
            //      title: "Failed",
            //      description: "Error in sending mail.",
            //      status: "error",
            //      position: "bottom",
            //      duration: 3000,
            //      isClosable: true,
            //  })
         }
     }
     



    const handleEmails =(e)=>{
          setEmails(e.target.value)

    }
  
    const handleUser =(e)=>{
       
      SetUser(e.target.value)

    }

     const handleSubject=(e)=>{
   setSubject(e.target.value)
     }


    const handleFirstName =(e)=>{
      setFirstName(e.target.value)
    }

    const handleTextArea=(e)=>{
      setText(e.target.value)
    }
    const handleEdit=()=>{
   setBtn(!btn)
    }
    // const handleSave=()=>{
    //       setBtn(!btn)
    // }


    useEffect(()=>{
       
      

      setFirstName(`Hi ${finalData?.first_name},`)
      SetUser(`${finalData?.from_full_name } ${finalData?.from_mail_}`)
      setFromMail(finalData?.from_mail_)

    },[finalData])

    useEffect(()=>{
console.log("row hello");
console.log("roww data useeffect", rowData);
      const emailcontact=  rowData?.original?.invoice_contact 
      console.log('rowww', emailcontact );

      // if (emailcontact !== undefined && emailcontact !== null && emailcontact !== "") {
      //   setEmails(emailcontact);
      // } else {
        // Handle case where emailContact is undefined, null, or empty
        setEmails(finalData?.email || '');
      // }
    console.log("finaldata>>",finalData);

    },[rowData?.original?.invoice_contact,finalData])


    useEffect(()=>{

      if(isOpen){
        setDate("")
        handleFollowUpOffer()
      }
      setBtn(false)
      onhandleState(DealsIcon)
    },[isOpen])


    useEffect(() => {
   
      
    }, [isOpen]);
  
    const SendFollowUpOffer = async()=>{
      // const dates= new Date();

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      console.log("Timezone:", timezone);
 
      SetDealsIcon(true)
      console.log("date in api",date);
      const object = {
         date       :date,
         to_mail    : emails,
         from_mail  : fromMail,
         subject    :subject,
         body       : `${firstName}\n\n${text}`,
         deal_id:rowData?.original?.deal_id,
         timezone:timezone
      }
       try {
           const result = await axios.post(`${BASE_URL}/send-mail-brandrep/`,object,{
               headers:{
                   Authorization : `Bearer ${token}`
               }
           })

           console.log("result>>>",result)
           if(result.status === 200){
            setFinalData(result.data)
            SetDealsIcon(true)
            fetchinfluencerDealsData()
           
           onClose();
       }
           setEmails()
       } catch (error) {
           console.log("error>>>",error)
           toast({
               title: "Failed",
               description: "Error in sending mail.",
               status: "error",
               position: "bottom",
               duration: 3000,
               isClosable: true,
           })
       }
   }
   

    
  return (
    <div>
       {/* <Button onClick={isOpen}>Open Modal</Button> */}
<Box >

<Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Schedule Follow UP </ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      
      <Box className='color-b'>
         
            <Box className='formBox'>
                
                <Input
                value={date}
                type='datetime-local'
                 placeholder='Select Date and Time'
                 size='md'
                w={300}
                onChange={handleDateChange}
                />

        </Box>

        <Flex className='header-message spaceing'>
            <Text fontSize='2xl' mt={3}>Preview Message</Text>
            <Button  onClick={handleEdit} colorScheme='orange' >Edit</Button>
        </Flex>
        {  btn ? (<Box mt={5} className='border-box'>
            <FormControl maxW={"100%"}  className='formControl' >
                <Flex className='header-message' >
                 <FormLabel className='w-88'>Send Date: </FormLabel>
                 <Input type='text' placeholder='Date' value={newDate} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"} className='formControl'  >
                <Flex className='header-message'>
                 <FormLabel>To:&nbsp;</FormLabel>
                    <Input type='text'   className="disabled-bold"  placeholder='Prospect Email' value={emails} onChange={handleEmails} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"}  className='formControl'  >
                <Flex className='header-message'>
                 <FormLabel>From:&nbsp;</FormLabel>
                    <Input type='text' placeholder='username or useremail' value={user} onChange={handleUser} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"} className='formControl'   >
                <Flex className='header-message'>
                 <FormLabel>Subject:&nbsp;</FormLabel>
                    <Input type='text' placeholder='Talent name ' value={subject}  onChange={handleSubject} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"}  className='formControl border-none' >
                <Flex>
                    <Input type='text' placeholder='Talent name ' value={firstName} onChange={handleFirstName} />
                    </Flex>
                    </FormControl>  

            <FormControl maxW={"100%"}  className='formControl border-none-two' >
                <Flex>
                    <Textarea type='text' height={150} placeholder='Talent name' value={text} onChange={handleTextArea}  />
                    </Flex>
                    </FormControl> 

          </Box>
           ) : 
           
          <Box mt={5} className='border-box'>
            <FormControl maxW={"100%"}  className='formControl' >
                <Flex className='header-message'>
                 <FormLabel className='w-88'>Send Date: </FormLabel>
                 <Input type='text' placeholder='Date' disabled value={newDate} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"} className='formControl'  >
                <Flex className='header-message'>
                 <FormLabel>To:&nbsp;</FormLabel>
                    <Input type='text'   className="disabled-bold" disabled  placeholder='Prospect Email' value={emails} onChange={handleEmails} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"}  className='formControl'  >
                <Flex className='header-message'>
                 <FormLabel>From:&nbsp;</FormLabel>
                    <Input type='text' disabled placeholder='username or useremail' value={user} onChange={handleUser} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"} className='formControl'   >
                <Flex className='header-message'>
                 <FormLabel>Subject:&nbsp;</FormLabel>
                    <Input type='text'  disabled placeholder='Talent name ' value={subject}  onChange={handleSubject} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"}   className='formControl border-none' >
                <Flex >
                    <Input type='text' disabled placeholder='Talent name ' value={firstName} onChange={handleFirstName} />
                    </Flex>
                    </FormControl>

            <FormControl maxW={"100%"}   className='formControl border-none-two'  >
                {/* <Flex >
                    <Textarea type='text' disabled height={150} placeholder='Talent name' value={text} onChange={handleTextArea}  />
                    <p dangerouslySetInnerHTML={{ __html: hyperlinkedText }}>{text}</p>
                    </Flex> */}
                        <Flex className='header-message'>
                                                <Box 
                                                    border='1px solid gray' 
                                                    borderRadius='5px' 
                                                    padding='5px' 
                                                    width='100%'
                                                    dangerouslySetInnerHTML={{ __html: hyperlinkedText }}
                                                />
                                            </Flex>
                    </FormControl> 

          </Box>
  }
   
          

           
             
    {/* <Button  onClick={handleEdit} colorScheme='orange'>Edit</Button> */}
    </Box>
    </ModalBody>

    <ModalFooter className='footer-p-0'>
      <Button colorScheme='blue' mr={3} onClick={SendFollowUpOffer}  >
        Submit
      </Button>
      <Button   colorScheme='red' onClick={onClose}>Cancel</Button>
    </ModalFooter>
  </ModalContent>
</Modal>
</Box>
    </div>
  )
}

export default DealsFollowUpModel
