import React from "react";
import {
  List,
  ListItem,
  Divider,
  HStack,
  Skeleton,
  Text,
  Button,
  ButtonGroup,
  Box,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Checkbox from "./Checkbox";
import "./InboxEmail.css";
import AllMessegesModel from "../Modal/AllMessegesModel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoMdTrash } from "react-icons/io";
import ScoutDeleteDialogeBox from "../Modal/ScoutDeleteDialogeBox";
import { useToast } from "@chakra-ui/react";
import { GiArtificialIntelligence } from "react-icons/gi";
import { BsPencilSquare } from "react-icons/bs";
import EmailComposer from "../Modal/EmailComposer";
import { useNotification } from "../../services/NotificationContext";

const InboxAllEmails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { allEmailsData, selectedEmail, isNonGeneral, currentEmail } =
    location.state || {};
  const [receivedData, setReceivedData] = useState(allEmailsData);
  const [isActive, setIsActive] = useState("Unread");
  const { colorMode } = useColorMode();
  const [pageCount, setPageCount] = useState(0);
  const [isOpenScoutDeleteDialogBox, setIsOpenScoutsDeleteDialogBox] =
    useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const toast = useToast();
  const [selectedRows, setSelectedRows] = useState([]);

  // const handleGetEmail = useSelector((state) => state.myFunction)
  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");
  const userRoute = routesConfig.find((route) => route.role === role)?.path;
  const receivedDatas = location.state;
  //const [isLoading, setIsLoading] = useState(false)
  const [threadId, setThreadId] = useState("")
  const [messeges, setMesseges] = useState(null);
  const [emailData, setEmailData] = useState(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [isEmailComposer, setIsEmailComposer] = useState(false);
  // const setIsActive = useSelector((state) => state.setIsActiveFunc);
  const setSelectedEmail = useSelector((state) => state.setSelectedEmailFunc);
  const setAllMails = useSelector((state) => state.setAllMailsFunc);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const UserEmail = localStorage.getItem("userEmail");
  const { handleEmailRead } = useNotification();

  // const snippets = receivedData?.emails?.map((item) => item.messages[0].subject);
  const extractTime = (dateString) => {
    const timeMatch = dateString.match(/\d{2}:\d{2} [APM]{2}/);
    return timeMatch ? timeMatch[0] : "";
  };
  const getAllMessagesAPI = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-single-inbox/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          thread_id: threadId,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to fetch messages");
      }
      const data = await response.json();
      const allMessages = data.emails.flatMap((email) => email.messages);
      setMesseges(allMessages);
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };
  
  const hanldeTextSubject = async (messages, data , tab) => {
    setIsActive(tab)
    setThreadId(data.thread_id)
    getAllMessagesAPI();
    setEmailData(data);

    if (messages.some((email) => email.status === "unread")) {
      // // Filter out the unread message IDs
      // const unreadMessageIds = messages
      //   .filter(message => message.status === 'unread')
      //   .map(message => message.id,message.thread_id);

      // // Hit the fetch POST API
      // try {
      //   const payload = {
      //     ids: unreadMessageIds
      //     thread_id:thread_id
      //   };

      const unreadMessages = messages.filter(
        (message) => message.status === "unread"
      );

      const unreadMessageIds = unreadMessages.map((message) => message.id);
      const unreadThreadIds = unreadMessages.map(
        (message) => message.thread_id
      );

      try {
        const payload = {
          ids: unreadMessageIds,
          thread_id: unreadThreadIds,
        };

        const response = await fetch(BASE_URL + "/make-read/", {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (data.status === "success") {
          if (!isNonGeneral) {
            handleGetEmail("", "unread");
            getTalentDetails();
            handleEmailRead();
          } else {
            handleTalentEmails("unread");
            getTalentDetails();
            handleEmailRead();
          }
        }
        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.status === 200) {
          // handleGetEmail('', 'unread')
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const getTalentDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-talent-mail-status/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      localStorage.setItem("total_count", data.total_count);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function removeContentBetweenBrackets(str) {
    return str?.replace(/<[^>]*>/g, "").trim();
  }

  function removeHtmlTags(str) {
    return str
      ? str
        .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, " ")
        .replace(/<[^>]*>/g, " ")
        .replace(/&nbsp;/g, " ")
        .replace(/\s+/g, " ")
        .trim()
      : "";
  }

  function getStringWidth(text) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = window.getComputedStyle(document.body).font;
    return context.measureText(text).width;
  }

  function sliceStringToFitScreen(text) {
    const screenWidth = window.innerWidth;
    const maxWidth = screenWidth * 0.18;
    const step = 10;
    let slicedText = "";
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const chunk = text.slice(currentIndex, currentIndex + step);

      const newText = slicedText + chunk;
      if (getStringWidth(newText) > maxWidth) {
        return slicedText.trimEnd() + "...";
      }
      slicedText = newText;
      currentIndex += step;
    }

    return slicedText;
  }

  // function processString(str) {
  //   const cleanedString = removeHtmlTags(str);
  //   return sliceStringToFitScreen(cleanedString);
  // }

  function processString(str) {
    const decodedString = decodeHtmlEntities(str);
    const cleanedString = removeHtmlTags(decodedString);
    return sliceStringToFitScreen(cleanedString);
  }

  function decodeHtmlEntities(str) {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  const HandleExit = () => {
    navigate(`${userRoute}/Inbox`, { state: receivedData });
    // setIsActive(isActive)
    // setAllMails(null)
    // setSelectedEmail('')
  };

  const handleTalentEmails = async (filter) => {
    try {
      const response = await fetch(
        BASE_URL +
        `/get-talent-mail/?page=${pageIndex + 1
        }&page_size=15&filter_type=${filter}`,
        {
          method: "POST",
          body: JSON.stringify({ email: currentEmail }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data.message == "No threads found for the provided email.") {
        setReceivedData([]);
        setPageCount(data?.paginator?.total_pages);
      } else {
        setReceivedData(data);
        setPageCount(data?.paginator?.total_pages);
      }

      // setAllMails(true);
      // setIsNOnGeneral(true);
      // setAllTalentEmailsData(data);
      // setSelectedEmail(email)
      // setcurrenttalentname(talent)
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.status === 200) {
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGetEmail = async (pagetoken = "", filter = "") => {
    setIsLoading(true);
    const email = UserEmail;
    try {
      const response = await fetch(
        `${BASE_URL}/get-general-mail/?page=${pageIndex + 1
        }&page_size=15&filter_type=${filter}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();
      console.log("data from all emails ", data);
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        console.log("data received", data);
        setReceivedData(data);
        setIsLoading(false);
        setPageCount(data?.paginator?.total_pages);

        // Additional success handling logic here
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show toast, update state, etc.)
    }
  };
  
  // useEffect(() => {
  //   // Update receivedData if location.state changes
  //   if (allEmailsData)
  //     setReceivedData(allEmailsData);
  //   console.log("hiiiiiiiirec");

  // }, [allEmailsData]);

  // useEffect(()=>{
  // handleGetEmail("",'unread')
  // },[])

  useEffect(() => {
    if (!isNonGeneral) {
      handleGetEmail("", isActive.toLowerCase());
    } else {
      handleTalentEmails(isActive.toLowerCase());
    }
  }, [pageIndex, isActive, isNonGeneral]);

  const handleTabs = (tab) => {
    setIsActive(tab);
    setPageIndex(0);
    if (tab == "Unread") {
      if (!isNonGeneral) {
        handleGetEmail("", "unread");
      } else {
        handleTalentEmails("unread");
      }
      // dispatch(setIsActiveFunc('Unread'))
      setIsActive("Unread");
    }
    if (tab == "Read") {
      if (!isNonGeneral) {
        handleGetEmail("", "read");
      } else {
        handleTalentEmails("read");
      }
      setIsActive("Read");
      // dispatch(setIsActiveFunc('Read'))
    }
    if (tab == "All") {
      if (!isNonGeneral) {
        handleGetEmail("", "all");
      } else {
        handleTalentEmails("all");
      }
      setIsActive("All");
      // dispatch(setIsActiveFunc('All'))
      // handleGetEmail('', "All")
    }

    if (tab == "Offers") {
      if (!isNonGeneral) {
        handleGetEmail("", "offers");
      } else {
        handleTalentEmails("offers");
      }
      setIsActive("Offers");
      // dispatch(setIsActiveFunc('Others'))
    }

    if (tab == "Replies") {
      if (!isNonGeneral) {
        handleGetEmail("", "replies");
      } else {
        handleTalentEmails("replies");
      }
      setIsActive("Replies");
      // dispatch(setIsActiveFunc('Replies'))
    }

    if (tab == "Leads") {
      if (!isNonGeneral) {
        handleGetEmail("", "leads");
      } else {
        handleTalentEmails("leads");
      }
      setIsActive("Leads");
      // dispatch(setIsActiveFunc('Leads'))
    }

    if (tab == "Unofficial") {
      if (!isNonGeneral) {
        handleGetEmail("", "unofficial");
      } else {
        handleTalentEmails("unofficial");
      }
      setIsActive("Unofficial");
      // dispatch(setIsActiveFunc('Unofficial'))
    }
  };

  const handleNext = () => {
    if (pageIndex < pageCount - 1) {
      setPageIndex(pageIndex + 1);
      // handleGetEmail()
    }
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const handleCheckboxChange = (e, item, isChecked) => {
    e.stopPropagation();
    setSelectedRows((prevSelectedRows) => {
      let newSelectedRows;
      if (isChecked) {
        newSelectedRows = [...prevSelectedRows, item];
      } else {
        newSelectedRows = prevSelectedRows.filter((row) => row !== item);
      }
      setShowDeleteIcon(newSelectedRows.length > 0);
      return newSelectedRows;
    });
  };

  const handleDeleteRows = async (selectedRows) => {
    const threadIds = selectedRows.map((item) => item.thread_id);
    const messageIds = selectedRows
      .map((item) => item.messages.map((message) => message.thread_id))
      .flat();
    try {
      setIsLoading(true);
      const dealIds = selectedRows.map((row) => row?.original?.deal_id);

      const response = await fetch(BASE_URL + "/trash-message/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: messageIds,
          thread_id: threadIds,
        }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (response.ok) {
        setIsLoading(false);

        setReceivedData((prevData) => {
          const filteredEmails = prevData.emails.filter(
            (emailObj) => !threadIds.includes(emailObj.thread_id)
          );

          return {
            ...prevData,
            emails: filteredEmails,
          };
        });

        if (!isNonGeneral) {
          handleGetEmail("", isActive.toLowerCase());
        } else {
          handleTalentEmails(isActive.toLowerCase());
        }
        toast({
          title: "Deletion Success",
          description: "mail was deleted",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        setShowDeleteIcon(false);
        setSelectedRows([]);
        setIsOpenScoutsDeleteDialogBox(false);
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete Scout .",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenScoutsDeleteDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete Scout.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setIsOpenScoutsDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  const ShowEmailComposer = () => {
    setIsEmailComposer(true);
  };

  const onClose = () => {
    setIsEmailComposer(false);
  };


  return (
    <div>
      {messeges !== null ? (
        <AllMessegesModel
          allEmailsData={receivedData}
          threadId={threadId}
          emailData={emailData}
          receivedData={receivedData}
          messages={messeges}
          setMesseges={setMesseges}
          isActive={isActive}
          setIsActive={setIsActive}
          selectedEmail={selectedEmail}
          isNonGeneral={isNonGeneral}
          handleGetEmail={handleGetEmail}
          handleTalentEmails={handleTalentEmails}
          currentEmail={currentEmail}
          setPageIndex={setPageIndex}
          handleTabs={handleTabs}
          getAllMessagesAPI={getAllMessagesAPI}
        />
      ) : (
        <>
          <div className="button-divider-container">
            {(selectedRows.length > 0 || showDeleteIcon) && (
              <Button
                onClick={() => setIsOpenScoutsDeleteDialogBox(true)}
                size={"sm"}
                colorScheme="red"
                variant="solid"
                position="absolute"
                right="55px"
                top="73px"
                height="28px"
                padding="8px"
                marginRight="6px"
              >
                <IoMdTrash size={20} color="white" />
              </Button>
            )}

            <Button onClick={HandleExit}>
              <GoChevronLeft size={24} />
            </Button>

            <div className="bspencilsquare_icons">
              <BsPencilSquare onClick={ShowEmailComposer} cursor="pointer" />
              <Divider />
            </div>
          </div>

          <ButtonGroup
            className="email_filter_btn"
            marginLeft={10}
            marginTop={5}
            colorScheme="blue"
            size="sm"
            variant="outline"
            spacing="6"
            margin="0"
            display="flex"
            flexWrap="wrap"
            padding="10px 5px"
            gap="10px"
            justifyContent="center"
          >
            <Button
              size="sm"
              onClick={() => handleTabs("Unread")}
              className={isActive == "Unread" ? "active" : ""}
            >
              Unread
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Read")}
              className={isActive == "Read" ? "active" : ""}
            >
              Read
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("All")}
              className={isActive == "All" ? "active" : ""}
            >
              All
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Offers")}
              className={isActive == "Offers" ? "active" : ""}
            >
              Offers
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Replies")}
              className={isActive == "Replies" ? "active" : ""}
            >
              Replies
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Leads")}
              className={isActive == "Leads" ? "active" : ""}
            >
              Leads
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Unofficial")}
              className={isActive == "Unofficial" ? "active" : ""}
            >
              Unofficial
            </Button>
          </ButtonGroup>

          <div className="email_inbox_outer">
            <Skeleton isLoaded={!isLoading}>
              <List spacing={3} my={8} className="email_list_box">
                {/* Check if there are no emails */}
                {receivedData?.emails?.length === 0 ? (
                  <Text
                    textAlign="center"
                    mt={4}
                    fontSize="lg"
                    color="gray.500"
                  >
                    No messages found
                  </Text>
                ) : (
                  receivedData?.emails?.map((item, index) => {
                    const messages = item.messages || [];
                    const hasMessages = messages.length > 0;

                    const subject = hasMessages
                      ? messages[messages.length - 1]?.subject
                      : "No Subject";
                    const snippet = hasMessages
                      ? messages[messages.length - 1]?.snippet
                      : "No Snippet";
                    const from = hasMessages
                      ? messages[0]?.from_email || "No Sender"
                      : "No Sender";

                    const status = messages.some(
                      (message) => message.status === "unread"
                    )
                      ? "unread"
                      : "read";
                    const date = hasMessages
                      ? messages[messages.length - 1]?.created_at
                      : null;
                    // const time = date
                    //   ? new Date(date).toLocaleString()
                    //   : "No Date";
                    const isAutoResponsed =
                      hasMessages && isNonGeneral
                        ? messages[0].is_auto_respond === true
                        : "";

                    return (
                      <React.Fragment key={index}>
                        <Divider />
                        <ListItem
                          display={"flex"}
                          justifyContent={"space-between"}
                          my={4}
                          mx={"20px"}
                        >
                          <HStack className="left_emailside">
                            <Checkbox
                              isChecked={selectedRows.includes(item)}
                              onChange={(e) =>
                                handleCheckboxChange(e, item, e.target.checked)
                              }
                            />
                            <Box
                              className="list_row"
                              onClick={() =>
                                hanldeTextSubject(item.messages, item , isActive)
                              }
                            >
                              <Text
                                className={
                                  status === "unread"
                                    ? "list_item_t"
                                    : "list_item_unread subject_desc"
                                }
                              >
                                <div className="subject_name">
                                  <span className="from_span">
                                    {removeContentBetweenBrackets(from)}
                                  </span>

                                  <span className="email_tag">
                                    <strong
                                      className={
                                        status === "read"
                                          ? "subject_strong-un"
                                          : "subject_strong"
                                      }
                                    >
                                      {subject}
                                    </strong>
                                    &nbsp;-&nbsp;{" "}
                                    <span className="snippet_span">
                                      {processString(snippet)}
                                    </span>
                                  </span>
                                  {isAutoResponsed && (
                                    <GiArtificialIntelligence
                                      size={30}
                                      color="blue"
                                    />
                                  )}
                                </div>
                              </Text>
                            </Box>
                          </HStack>
                          <HStack className="right_emailside">
                            <Text
                              className={
                                status === "unread"
                                  ? "time-display"
                                  : "time-display-unread message_time"
                              }
                            >
                              {date}
                            </Text>
                          </HStack>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })
                )}
              </List>
            </Skeleton>
          </div>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex className="inbox_pagination_content">
              Page&nbsp;
              <Text fontWeight={500}>
                {pageIndex + 1} of {pageCount}
              </Text>
            </Flex>
            {/* <div className='paents-iconmove'> */}
            <Flex
              alignItems={"center"}
              gap={3}
              className="inbox_pagination_button"
            >
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronLeft
                  className="Left-next-page"
                  size={24}
                  onClick={() => handlePrevious()}
                  disabled={pageIndex === 0}
                />
              </Box>

              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronRight
                  className="Right-next-page"
                  size={24}
                  onClick={() => handleNext()}
                />
              </Box>
            </Flex>

            {/* </div> */}
          </Flex>
          {/* <div>
            <text>{pageIndex +1} of {pageCount}</text>
          </div> */}
          <ScoutDeleteDialogeBox
            isOpen={isOpenScoutDeleteDialogBox}
            onClose={() => {
              setIsOpenScoutsDeleteDialogBox(false);
            }}
            isLoading={isLoading}
            // selectedFlatRows={selectedFlatRows}
            selectedFlatRows={selectedRows}
            handleDeleteRows={handleDeleteRows}
          />
          {isEmailComposer && <EmailComposer onClose={onClose} />}
        </>
      )}
    </div>
  );
};

export default InboxAllEmails;
