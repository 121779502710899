import React from 'react';
import './EventsCalendar.css';
import moment from 'moment';

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToPrevYear = () => {
    const date = moment(toolbar.date).subtract(1, 'year').toDate();
    toolbar.onNavigate('DATE', date);
  };

  const goToNextYear = () => {
    const date = moment(toolbar.date).add(1, 'year').toDate();
    toolbar.onNavigate('DATE', date);
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        <span className="rbc-toolbar-label">{date.format('MMMM YYYY')}</span>
      </span>
    );
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={goToPrevYear}>
          ««
        </button>
        <button type="button" onClick={goToBack}>
          «
        </button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={goToNext}>
          »
        </button>
        <button type="button" onClick={goToNextYear}>
          »»
        </button>
      </span>
    </div>
  );
};

export default CustomToolbar;
