import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  useToast,
  Select,
  Text,
  HStack,
  Flex,
  Input,
  InputGroup,
  IconButton,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/react";
import { ApprovedEmailLists } from "../../utils/ApprovedEmailLists";
import { SequencesLists } from "../../utils/SequencesLists";
import { MdArrowDropDown, MdSearch } from "react-icons/md";
import { ConfirmCampaignDialogBox } from "./ConfirmCampaignDialogBox";
import { createCampaign } from "../../services/campaignApi";
import { SearchIcon } from '@chakra-ui/icons';
import "../Table/datepicker.css";
import { useSelector } from "react-redux";

export const CreateCampaignModal = ({
  isOpen,
  setIsCreateCampaignModalOpen,
  selectedPropspects,
  influencers,
  emailList,
  sequences,
  onChange,

}) => {
  const [selectedSequence, setSelectedSequence] = useState(sequences[0]);
  const [selectedEmail, setSelectedEmail] = useState();
  const [selectedInfluencer, setSelectedInfluencer] = useState({ influencersName: "", influencerId: "" });
  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [isConfirmDialogBoxOpen, setIsConfirmDialogBoxOpen] = useState();
  const [isApproving, setIsApproving] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(sequences);
  const [filterValue, setFilterValue] = useState('')
  const [selectedInfluencerValue, setSelectedInfluencerValue] = useState([]);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const toast = useToast();
  // const token = ;
   const token= (useSelector((state)=>state.token) ||  localStorage.getItem('token'))
  //  console.log('tokens ss from selector',tokens);
   
  console.log('token from create camp modal>>',token)

   console.log('influencersssssss ',influencers);
   
  useEffect(() => {
    setSearchResults(sequences)
  }, [sequences])

  const updateSelectedSequence = (seq) => {
    setSelectedSequence(seq);
  };
  const updateSelectedEmail = (email) => {
    setSelectedEmail(email);
  };


  const handleCreateCampaignClick = () => {
    setIsConfirmDialogBoxOpen(true)
  }

  const extractProspectIds = () => {
    const prospect_ids = selectedPropspects?.map((prop, index) => {
      return prop.original.prospect_id
    })
    console.log(prospect_ids);
  }

  const createNewCampaign = async () => {
    const prospect_ids = selectedPropspects?.map((prop, index) => {
      return prop.original.prospect_id
    })
    console.log(prospect_ids);
    const payload = {
      influencer_id: selectedInfluencer.influencerId,
      sequence_id: selectedSequence.id,
      sender_email: selectedEmail,
      prospect_ids: prospect_ids,
    }
    console.log(payload);
    try {
      const campaign = await createCampaign(payload,token)
      console.log(campaign);
      if (campaign.error_ids.length > 0) {
        toast({
          title: `Failed for ${campaign.error_ids.length}`,
          description: campaign.error_ids,
          status: "info",
          position: "bottom",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: "All campaigns created successfully",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

      }

      setIsConfirmDialogBoxOpen(false);
      onClose();
    } catch (error) {
      console.error("after failing", error)
      toast({
        title: "Error",
        description: "Failed to create campaign.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      setIsConfirmDialogBoxOpen(false);
    }
  }

  const handleToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSearchText("");
  };

  const onClose = () => {
    setIsCreateCampaignModalOpen(false);
    setSelectedInfluencerValue([]);
    setSelectedInfluencer({ ...selectedInfluencer, influencersName: '', influencerId: '' });
    setSearchResults(sequences)
  }

  const fetchData = async (searchQuery) => {
    try {
      const response = await fetch(BASE_URL + `/allsequence/?name=${searchQuery}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setSearchResults(data['Seqeunce'])
    } catch (error) {
      console.log(error)
    }
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    setFilterValue(value)
    fetchData(value);
  }


  useEffect(() => {
    if (selectedSequence && selectedEmail && selectedInfluencer.influencerId) {
      setIsCreateDisabled(false);
    } else {
      setIsCreateDisabled(true);
    }
  }, [selectedEmail, selectedSequence, selectedInfluencer]);


  const handleSelectedInfluencer = (influencerId, influencerName) => {
    setSelectedInfluencerValue([influencerId]);
    setSelectedInfluencer({ ...selectedInfluencer, influencersName: influencerName, influencerId: influencerId })
    setSearchText('');
  };

  console.log(selectedInfluencer, "selectedInfluencer")

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.custom-dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Modal size={"full"} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Create Campaign
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {/* <Email List /> */}
            <Flex gap={2} border={''}>
              <ApprovedEmailLists
                emailList={emailList}
                updateSelectedEmail={updateSelectedEmail}
              />
              {/* .. */}

              <HStack gap={1} position="relative" className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`}>
                <Text as={"samp"} position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="2" color={"blue"} boxSizing={"borderBox"} >
                  Talent Name
                </Text>

                <div className="nice-select">
                  <div onClick={handleToggle}>
                    {selectedInfluencerValue.length === 0
                      ?
                      <div className="select-talent-name">
                        <span>Select Talent name</span>
                        <MdArrowDropDown color="gray.300" />
                      </div>

                      :

                      <div className="select-talent-name">
                        {selectedInfluencerValue.map((value) => {
                          return influencers.find((influencer) => influencer.id === value)?.name
                        })}
                        <MdArrowDropDown color="gray.300" fontSize='22px' />
                      </div>
                    }

                    {isDropdownOpen && (
                      <div className="talent-dropdown">
                        <div className="nice-select-search-box">
                          <InputGroup>
                            <Input
                              type="text"
                              placeholder="Search a Talent..."
                              value={searchText}
                              onChange={(event) => {
                                event.stopPropagation();
                                setSearchText(event.target.value);
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </InputGroup>
                        </div>
                        <div>
                          <ul className="list">
                            {influencers.filter((influencer) => influencer.name.toLowerCase().includes(searchText.toLowerCase())).map((influencer) => (
                              <li
                                key={influencer.id}
                                className="list"
                                onClick={() => handleSelectedInfluencer(influencer.id, influencer.name)}
                              >
                                {influencer.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                {/* .. */}
                {/* <Select className="custom-dropdown"
                  w={"300px"}
                  size={"sm"}
                  onClick={handleSelectClick}
                  icon={<MdArrowDropDown />}
                  value={selectedInfluencer.influencersName}
                  placeholder={selectedInfluencer.influencersName ? selectedInfluencer.influencersName : ""}
                >
  {influencers
                      .filter((influencer) => influencer.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((filteredInfluencer, index) => (

                        <option key={filteredInfluencer.id} value={filteredInfluencer.id} onClick={() => handleClick(filteredInfluencer.name, filteredInfluencer.id)}>
                          {filteredInfluencer.name}
                        </option>
                      ))}
                </Select> */}
                {/* <InputGroup>
                  <Input
                    className="custom-dropdown"
                    w="300px"
                    h="2.5rem"
                    color={'#555'}
                    fontWeight={'400'}
                    fontSize={'15px'}
                    onClick={handleSelectClick}
                    value={selectedInfluencer.influencersName}
                    placeholder={selectedInfluencer.influencersName ? selectedInfluencer.influencersName : "Select Talent name"}
                  />
                  <InputRightElement
                    pointerEvents="none"
                    fontSize='22px'
                    children={<MdArrowDropDown color="gray.300" />}
                  />
                </InputGroup> */}
                {/* {isDropdownOpen && (
                <div  style={{
                  position: 'absolute', top: '100%', left: 0, width: '100%', zIndex: 1, background:
                    "#ffffff"
                }}>
                  <InputGroup style={{position:"relative"}}>
                    <Input
                      type="text"
                      placeholder="Search a Talent..."
                      value={searchTerm}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setSearchTerm(e.target.value)
                        setSelectedInfluencer(e.target.value)
                      }}
                    />
                  </InputGroup>

                  <div style={{ maxHeight: '200px', overflowY: 'auto', background: "#FFFFFF", zIndex: 1, cursor: "pointer", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", border: " 1px solid black", paddingLeft: "15px" }}>
                    {influencers
                      .filter((influencer) => influencer.name.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((filteredInfluencer, index) => (

                        <option key={filteredInfluencer.id} value={filteredInfluencer.id} onClick={() => handleClick(filteredInfluencer.name, filteredInfluencer.id)}>
                          {filteredInfluencer.name}
                        </option>
                      ))}

                  </div>
                </div>
               )}  */}

              </HStack>

              {/* .. */}


              <HStack gap={1} position="relative" marginTop={{ base: "50px", sm: "0" }}>
                <Text as={"samp"} position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1" color={"blue"}>Selected prospects </Text>
                <Select
                  w={"300px"}
                  //   size={"sm"}
                  //   width={"30%"}
                  //   onChange={handleEmailChange}
                  // isDisabled
                  icon={<MdArrowDropDown />}
                // placeholder="selected a prospects"
                >
                  {selectedPropspects?.map((prospect, index) => {
                    return (
                      <option
                        isDisabled
                        key={prospect.original.prospect_id}
                        value={prospect.original.full_name}>
                        {prospect.original.full_name}
                      </option>
                    );
                  })}

                </Select>
              </HStack>
            </Flex>

            {/* sequences List */}
            <SequencesLists
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              handleInputChange={handleInputChange}
              sequences={searchResults}
              updateSelectedSequence={updateSelectedSequence}
            />
          </Box>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            isDisabled={isCreateDisabled}
            isLoading={isApproving}
            colorScheme="red"
            onClick={handleCreateCampaignClick}
          >
            Create
          </Button>
          <Button colorScheme="yellow" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
      <ConfirmCampaignDialogBox
        isOpen={isConfirmDialogBoxOpen}
        onClose={() => setIsConfirmDialogBoxOpen(false)}
        onYes={createNewCampaign}
      />
    </Modal>
  );
};
