import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, FormControl, FormLabel, Input, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';

const ChakraDatePicker = ({ label, selectedDate, onChange, onBlur,name,row }) => {

    const handleDateChange = (date) => {
        onChange(date, name, row);
      };
  return (
    <FormControl >
      {label && <FormLabel>{label}</FormLabel>}
      <Box style={{ resize: "both", minWidth: "150px"}} >
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          onBlur={onBlur}
          customInput={<CustomInput  />}
          dateFormat="MM/dd/yyyy"
        />
      </Box>
    </FormControl>
  );
};

const CustomInput = React.forwardRef(({ value, onClick,onChange,onBlur }, ref) => (
    <InputGroup>
  <Input
    value={value}
    onClick={onClick}
    onChange={onChange}
    onBlur={onBlur}
    ref={ref}
    placeholder="Select date"
    //readOnly
  />
   <InputRightElement>
      <IconButton
        icon={<CalendarIcon />}
        onClick={onClick}
        variant="ghost"
      />
    </InputRightElement>
  </InputGroup>
));

export default ChakraDatePicker;
