
import { Toast, createStandaloneToast } from "@chakra-ui/react";


const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL; // Replace with your API's base URL
const token = localStorage.getItem('token')
//respose format
//   influencers = [
//     {
//         "id": "61f0a14e-31af-46d6-a866-7500853ecca0",
//         "name": "santhosh::::::: balaji"
//     }
// ]
export const fetchInfluencerMetaData = async (token) => {
    const url = `${BASE_URL}/get_influencers/`; // Adjust the endpoint URLhttp://127.0.0.1:8000/get_influencers
    try {
      const response = await fetch(url,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch inflencers');
      }
      const data = await response.json();
      return data.influencers;
    } catch (error) {

      throw error;
    }
  }