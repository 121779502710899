import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { Quill } from "react-quill";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// const Personlization = () => {
//   <Menu>
//     <MenuButton
//       border={0}
//       bgColor={"transparent"}
//       _hover={{ bgColor: "transparent", color: "teal" }}
//       _expanded={{ bgColor: "transparent", color: "teal" }}
//       as={Button}
//       size={"sm"}
//       rightIcon={<ChevronDownIcon />}
//     >
//       Personlization
//     </MenuButton>
//     <MenuList>
//       {personalizeList.map((variable) => (
//         <MenuItem key={variable} onClick={() => insertVariable(variable)}>
//           {variable}
//         </MenuItem>
//       ))}
//     </MenuList>
//   </Menu>;
// };

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

var FontStyle = Quill.import('attributors/style/font');
var ColorClass = Quill.import('attributors/class/color');
var SizeStyle = Quill.import('attributors/style/size');
Quill.register(FontStyle, true);
Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);
// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["xx-small","x-small", "small", "medium", "large", "x-large","xx-large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  'courier-new', 
  'courier',
  'roboto' ,
  'sans-serif' , 
  'monospace',
  'cursive',
  'math',
  'serif',
  'system-ui'
];
Quill.register(Font, true);

// Quill.register('modules/keyboard', function (Quill, options) {
//   const keyboard = Quill.getModule('keyboard');
//   keyboard.addBinding(
//     {
//       key: 'Enter',
//     },
//     function (range, context) {
//       const [line, offset] = this.Quill.getLine(range.index);
//       const text = line.text.trim();
//       if (!text) {
//         this.Quill.insertText(range.index, '\n', 'user');
//         return false;
//       }
//       return true;
//     }
//   );
// });


// Modules object for setting up the Quill editor
export const modules = {
  // toolbar: [
  //   [{ 'header': '1'}, {'header': '2'}],
  //   [ { font: ['arial','mirza', 'sans-serif', 'roboto'] }],
  //   [{size: []}],
  //   ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  //   [{'list': 'ordered'}, {'list': 'bullet'}, 
  //    {'indent': '-1'}, {'indent': '+1'}],
  //   ['link', 'image', 'video'],
  //   ['clean']
  // ] ,
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  // keyboard: {
  //   bindings: {},
  // },
  clipboard: {
    matchVisual: false
}
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      {/* <select className="ql-font">
        <option value='courier-new'>courier-new</option>
        <option value='courier'>courier</option>
        <option value='roboto'>roboto</option>
        <option value='sans-serif'>sans-serif</option>
        <option value='serif'>serif</option>
        <option value='math'>math</option>
        <option value='cursive'>cursive</option>
        <option value='monospace'>monospace</option>
        <option value='system-ui'>system-ui</option>
      </select> */}
      <select className="ql-size" defaultValue="medium"  >
        <option value='small'>small</option>
        <option value='x-small'>x-small</option>
        <option value='xx-small'>xx-small</option>
        <option value='medium'>medium</option>
        <option value='large'>large</option>
        <option value='x-large'>x-large</option>
        <option value='xx-large'>xx-large</option>
      </select>
    </span>

    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <button className="ql-clean" />
    </span>
    {/* <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span> */}
    {/* <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span> */}
    {/* <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span> */}
    <span className="ql-formats">
      <button className="ql-link" />
      {/* <button className="ql-image" /> */}
      {/* <button className="ql-video" /> */}
    </span>

    <span className="ql-formats"></span>
    {/* <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span> */}
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
