import React from 'react'
import { 
    AlertDialog, 
    AlertDialogBody, 
    AlertDialogContent, 
    AlertDialogFooter, 
    AlertDialogHeader, 
    AlertDialogOverlay, 
    Button, 
} from '@chakra-ui/react'

export const ApprovalConfirmDialogBox = ({ isOpen, onClose, onYes }) => {
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>Approve Campaign</AlertDialogHeader>

                    <AlertDialogBody>
                        Do you really want to approve this Campaign?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='yellow' onClick={onClose}>No</Button>
                        <Button colorScheme='red' ml={3} onClick={onYes}>Yes</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
