import { Box } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useRef } from 'react'

const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {

    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <Box>
            <input type='checkbox' ref={resolvedRef} {...rest} />
        </Box>
    )
})

export default Checkbox