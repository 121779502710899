import { Box, Button, Card, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormErrorMessage, FormLabel, Image, Input, InputGroup, InputLeftAddon, InputLeftElement, InputRightElement, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, NumberInput, NumberInputField, Select, Spinner, Tag, TagCloseButton, TagLabel, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { PulseLoader } from 'react-spinners';
import { FaUserPlus } from 'react-icons/fa';
import CampaignDialogBox from '../Modal/CampaignDialogBox';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { IoImages } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import { BiCaretDown } from 'react-icons/bi'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../Table/datepicker.css';
import { AiFillCalendar } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const InfluencerFormModal = ({ isOpen, onClose }) => {

    // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
    const token = localStorage.getItem('token')
    const navigate = useNavigate()


    const toast = useToast();
    const imageInputRef = useRef(null);
    const wishListRef = useRef(null);
    const blackListRef = useRef(null);

    const [startDate, setStartDate] = useState(null);
    const handleDateChange = (date) => {
        setStartDate(date);
        handleInfluencerDataChange({ target: { name: 'birthday', value: date } });
    };
    const [isOpenCampaignDialogBox, setIsOpenCampaignDialogBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [isWishListLoading, setIsWishListLoading] = useState(false);
    const [isBlackListLoading, setIsBlackListLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profilePictureURL, setProfilePictureURL] = useState("");
    const [searchCompanyDomain, setSearchCompanyDomain] = useState('');
    const [filteredCompanyDomainData, setFilteredCompanyDomainData] = useState([]);
    const [selectedDomains, setSelectedDomains] = useState({
        domainIDs: [],
        domainNames: []
    });

    const [searchMetaTag, setSearchMetaTag] = useState('');
    const [filteredMetaTagData, setFilteredMetaTagData] = useState([]);
    const [selectedBlackListMetaTags, setSelectedBlackListMetaTags] = useState({
        metaTagIDs: [],
        metaTags: []
    });

    const [activeMetaTagDropdown, setActiveMetaTagDropdown] = useState(false);
    const [metaTags, setMetaTags] = useState([]);
    const [selectedMetaTags, setSelectedMetaTags] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const routesConfig = [
        { path: "/admin", role: "Admin" },
        { path: "/creator", role: "Creator Manager" },
        { path: "/influencer", role: "Influencer" },
      ];
    
      const role =
        useSelector((state) => state.role) || localStorage.getItem("Role");
    
      const userRoute = routesConfig.find((route) => route.role === role)?.path;

      console.log("userRoutes>>",userRoute);
      

    const [InfluencerData, setInfluencerData] = useState({
        firstName: "",
        lastName: "",
        talentName: "",
        email: "",
        phone: "",
        address: "",
        birthday: "",
        youtubeUserName: "",
        instagramUserName: "",
        tiktokUserName: "",
        partialYoutubeRate: 0,
        dedicatedYoutubeRate: 0,
        youtubeShortsRate: 0,
        igReelRate: 0,
        igStoryRate: 0,
        igFeedRate: 0,
        tiktokRate: 0,
        usageRate: 0,
        // metaTag: "",
    });
    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        talentName: "",
        email: "",
        phone: "",
        address: "",
        birthday: "",
        // metaTag: ""
    })

    const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <Button variant={"outline"} display={'flex'} justifyContent={'start'} w={'410px'} leftIcon={<AiFillCalendar />} onClick={onClick} ref={ref} >
            {value ? value : "MM/DD/YY"}
        </Button>
    ));

    const handleInfluencerDataChange = (e) => {
        const { name, value } = e.target;
        setInfluencerData(prev => ({ ...prev, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: "" }));
    }

    const handleSubmit = () => {
        setIsOpenCampaignDialogBox(true);
    }

    const handleYes = () => {
        setIsOpenCampaignDialogBox(false);
        handleSubmittingData(true);
    };

    const handleNo = () => {
        setIsOpenCampaignDialogBox(false);
        handleSubmittingData(false);
    };

    const handleImageChange = (e) => {
        setSelectedImage(e.target.files[0]);
        imageInputRef.current.value = null;
    }

    const handleUploadProfilePicture = () => {
        const formData = new FormData();
        formData.append("file", selectedImage);
        setIsFileLoading(true);

        fetch(BASE_URL + '/profile/', {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("token");
                    navigate('/login');
                    throw new Error('Unauthorized: User session expired');
                }
                return response.json();
            })
            .then(data => {
                setIsFileLoading(false);
                setProfilePictureURL(data?.profile_url);
                setSelectedImage("");

                if (data.profile_url) {
                    toast({
                        title: 'Profile picture has been uploaded.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }
                else {
                    toast({
                        title: 'Failed to upload profile picture.',
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
            .catch(error => {
                setIsFileLoading(false);

                toast({
                    title: 'Failed to upload profile picture.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })

                console.error('Error:', error);
            })
    }

    const handleSubmittingData = (flag) => {
        if (!InfluencerData.firstName) {
            setErrors(prev => ({ ...prev, firstName: "First name is required." }));
            return;
        }

        if (!InfluencerData.lastName) {
            setErrors(prev => ({ ...prev, lastName: "Last name is required." }));
            return;
        }

        if (!InfluencerData.talentName) {
            setErrors(prev => ({ ...prev, talentName: "Talent name is required." }));
            return;
        }

        if (!InfluencerData.email) {
            setErrors(prev => ({ ...prev, email: "Email is required." }));
            return;
        }

        if (!InfluencerData.phone) {
            setErrors(prev => ({ ...prev, phone: "Phone is required." }));
            return;
        }

        if (!InfluencerData.address) {
            setErrors(prev => ({ ...prev, address: "Address is required." }));
            return;
        }

        if (!InfluencerData.birthday) {
            setErrors(prev => ({ ...prev, birthday: "DOB is required." }));
            return;
        }

        // if (selectedMetaTags.length === 0) {
        //     setErrors(prev => ({ ...prev, metaTag: "Meta tag is required." }));
        //     return;
        // }

        setIsLoading(true);

        const InfluencerPayload = {
            profile_picture: profilePictureURL,
            first_name: InfluencerData.firstName,
            last_name: InfluencerData.lastName,
            talent_name: InfluencerData.talentName,
            email: InfluencerData.email,
            phone: InfluencerData.phone,
            address: InfluencerData.address,
            birthday: InfluencerData.birthday,
            youTube_username: InfluencerData.youtubeUserName && `@${InfluencerData.youtubeUserName}`,
            instagram_username: InfluencerData.instagramUserName && `${InfluencerData.instagramUserName}`,
            tikTok_username: InfluencerData.tiktokUserName && `@${InfluencerData.tiktokUserName}`,
            partial_youTube_rate: InfluencerData.partialYoutubeRate,
            dedicated_youTube_rate: InfluencerData.dedicatedYoutubeRate,
            youTube_shorts_rate: InfluencerData.youtubeShortsRate,
            IG_reel_rate: InfluencerData.igReelRate,
            IG_Story_rate: InfluencerData.igStoryRate,
            IG_Feed_rate: InfluencerData.igFeedRate,
            tikTok_rate: InfluencerData.tiktokRate,
            usage_rate: InfluencerData.usageRate,
            meta_tags: selectedMetaTags,
            whitelisted_company_ids: selectedDomains.domainIDs,
            whitelisted_company_domains: selectedDomains.domainNames,
            blacklisted_company_ids: selectedBlackListMetaTags.metaTagIDs,
            flag: flag
        }

        fetch(BASE_URL + '/create_influencer/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(InfluencerPayload)
        })
            .then(response => {
                if (response.status === 401) {
                    localStorage.removeItem("userEmail");
                    localStorage.removeItem("token");
                    navigate('/login');
                }
                return response.json();
            })
            .then(data => {
                setIsLoading(false);

                if (data.message) {
                    toast({
                        title: 'Onboarding successful',
                        description: data.message,
                        status: 'success',
                        position: 'bottom',
                        duration: 3000,
                        isClosable: true,
                    })

                    setTimeout(() => {
                        setInfluencerData({
                            firstName: "",
                            lastName: "",
                            talentName: "",
                            email: "",
                            phone: "",
                            address: "",
                            birthday: "",
                            youtubeUserName: "",
                            instagramUserName: "",
                            tiktokUserName: "",
                            partialYoutubeRate: 0,
                            dedicatedYoutubeRate: 0,
                            youtubeShortsRate: 0,
                            igReelRate: 0,
                            igStoryRate: 0,
                            igFeedRate: 0,
                            tiktokRate: 0,
                            usageRate: 0,
                            metaTag: "",
                        })

                        setSelectedDomains({
                            domainIDs: [],
                            domainNames: []
                        })

                        setSelectedBlackListMetaTags({
                            metaTagIDs: [],
                            metaTags: []
                        });
                        setSelectedMetaTags([]);
                        setProfilePictureURL("");

                        if (userRoute == "/creator") {
                            window.location.href = '/creator/Outreach';
                            
                        }
                        else {
                            window.location.href = '/admin/influencer';
                        }
                    }, 3000);
                }
                else {
                    toast({
                        title: 'Onboarding Failed',
                        description: 'Failed to Onboarding',
                        status: 'error',
                        position: 'bottom',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
            .catch(error => {
                setIsLoading(false);

                toast({
                    title: 'Onboarding Error',
                    description: "Failed to Onboarding",
                    status: 'error',
                    position: 'bottom',
                    duration: 3000,
                    isClosable: true,
                })

                console.error('Error:', error);
            })
        // console.log(InfluencerPayload);
        // setIsLoading(false);
    }


    const fetchCompanyDomain = async () => {
        try {
            setIsWishListLoading(true);
            const response = await fetch(BASE_URL + `/whitelist-search/?company_domain=${searchCompanyDomain}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.code === 'token_not_valid') {

                localStorage.removeItem("userEmail");
                localStorage.removeItem("token")
                navigate('/login')
            }
            setFilteredCompanyDomainData(data?.companies);
            setIsWishListLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchBlacklistMetaTags = async () => {
        try {
            setIsBlackListLoading(true);
            const response = await fetch(BASE_URL + `/blacklist-search/?specialties=${searchMetaTag}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (data.code === 'token_not_valid') {

                localStorage.removeItem("userEmail");
                localStorage.removeItem("token")
                navigate('/login')
            }
            setFilteredMetaTagData(data?.companies);
            setIsBlackListLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const fetchMetaTags = async () => {
    //     try {
    //         const response = await fetch(BASE_URL + `/get-metatag/`);
    //         const data = await response.json();
    //         setMetaTags(data?.metatag);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // }

    const fetchMetaTags = async () => {
        try {
            const response = await fetch(`${BASE_URL}/get-metatag/`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            });

            const data = await response.json();
            if (data.code === 'token_not_valid') {
                localStorage.removeItem("userEmail");
                localStorage.removeItem("token")
                navigate('/login')
            }
            const tags = data?.metatag || [];
            const availableTags = tags.filter(tag => !selectedMetaTags.includes(tag));
            setMetaTags(availableTags);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchMetaTags();
    }, [selectedMetaTags]);


    useEffect(() => {
        if (activeMetaTagDropdown) {
            fetchMetaTags();
        }
    }, [activeMetaTagDropdown]);

    useEffect(() => {
        if (searchCompanyDomain.trim() !== '') {
            const timeout = setTimeout(() => {
                fetchCompanyDomain();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [searchCompanyDomain]);

    useEffect(() => {
        if (searchMetaTag.trim() !== '') {
            const timeout = setTimeout(() => {
                fetchBlacklistMetaTags();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [searchMetaTag])

    useEffect(() => {
        if (wishListRef.current) {
            wishListRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }

        if (blackListRef.current) {
            blackListRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }, [filteredCompanyDomainData, filteredMetaTagData]);

    const handleDomainClick = (id, domain) => {
        setSearchCompanyDomain('');
        if (!selectedDomains.domainIDs?.includes(id)) {
            setSelectedDomains({
                domainIDs: [...selectedDomains.domainIDs, id],
                domainNames: [...selectedDomains.domainNames, domain]
            });
        }
    };

    const handleBlackListMetaTagClick = (id, metaTag) => {
        setSearchMetaTag('');
        if (!selectedBlackListMetaTags.metaTagIDs?.includes(id)) {
            setSelectedBlackListMetaTags({
                metaTagIDs: [...selectedBlackListMetaTags.metaTagIDs, id],
                metaTags: [...selectedBlackListMetaTags.metaTags, metaTag]
            });
        }
    };

    const handleDomainTagClose = (domain) => {
        const updatedDomains = {
            domainIDs: selectedDomains.domainIDs.filter((_, index) => index !== selectedDomains.domainNames.indexOf(domain)),
            domainNames: selectedDomains.domainNames.filter(item => item !== domain)
        };
        setSelectedDomains(updatedDomains);
    };

    const handleBlackListMetaTagClose = (metaTag) => {
        const updatedMetaTags = {
            metaTagIDs: selectedBlackListMetaTags.metaTagIDs.filter((_, index) => index !== selectedBlackListMetaTags.metaTags.indexOf(metaTag)),
            metaTags: selectedBlackListMetaTags.metaTags.filter(item => item !== metaTag)
        };
        setSelectedBlackListMetaTags(updatedMetaTags);
    };

    // const handleMetaTagClick = (metaTag) => {
    //     setActiveMetaTagDropdown(false);
    //     if (!selectedMetaTags?.includes(metaTag)) {
    //         setSelectedMetaTags([...selectedMetaTags, metaTag]);
    //     }
    // }


    const handleMetaTagClick = (tag) => {
        // Add the selected tag to your state that tracks selected tags.
        if (!selectedMetaTags.includes(tag)) {
            setSelectedMetaTags([...selectedMetaTags, tag]);
        }

        // Remove the selected tag from the list of displayed tags.
        const updatedFilteredMetaTagData = filteredMetaTagData.filter(t => t !== tag);
        setFilteredMetaTagData(updatedFilteredMetaTagData);

        // Don't toggle the dropdown visibility here. This keeps it open.
    };


    const handleMetaTagClose = (metaTag) => {
        const updatedMetaTags = selectedMetaTags.filter(item => item !== metaTag);
        setSelectedMetaTags(updatedMetaTags);
    }

    const filteredMetaTags = metaTags?.filter(tag =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <Box>
            <Drawer size={'xl'} isOpen={isOpen} placement='right' onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton color={"#ffffff"} />
                    <DrawerHeader mb={3} bg={"linear-gradient(to right, #00AA45, #32bb6a, #66cc8f)"} color={"#ffffff"} fontWeight={"500"} fontSize={"2xl"}>
                        <Flex alignItems={"center"} gap={2}>
                            <FaUserPlus size={28} />
                            New Influencer
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody>
                        <Box>
                            <Flex gap={5} mb={6}>
                                <FormControl maxW={"50%"} border={"2px dashed blue"} borderRadius={"10px"} p={"30px 100px"}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Profile Picture</FormLabel>
                                    <Flex flexDirection={"column"} alignItems={"center"} color={"#6B6F7A"}>
                                        <IoImages size={25} />
                                        <Text fontSize={"sm"} mt={"3px"}>
                                            <Link textDecoration={"underline"} color={"blue"} onClick={() => imageInputRef.current.click()}>Click to upload</Link> an image
                                        </Text>
                                        {
                                            selectedImage &&
                                            <>
                                                <Flex alignItems={"center"} justifyContent={"center"}>
                                                    <Text>{selectedImage?.name}</Text>
                                                    {/* <RxCross2 size={16} color={"red"} cursor={"pointer"} onClick={() => setSelectedImage(null)}  />   */}
                                                </Flex>
                                                <Button isLoading={isFileLoading} mt={3} variant='outline' colorScheme='green' size={"sm"} onClick={handleUploadProfilePicture}>Upload</Button>
                                            </>
                                        }
                                        <Input type='file' name='profilePicture' style={{ display: 'none' }} onChange={handleImageChange} ref={imageInputRef} accept='image/*' />
                                    </Flex>
                                </FormControl>
                                {
                                    profilePictureURL &&
                                    <Box position={"relative"}>
                                        {/* <RxCross2 size={20} color={"#ffffff"} cursor={"pointer"} style={{ 
                                            position: 'absolute', 
                                            right: '0', 
                                            background: '#00AA45',
                                            borderTopRightRadius: "10px"
                                        }} onClick={() => setSelectedImage(null)}  />   */}
                                        <Image src={profilePictureURL} alt='image' style={{ height: "110px", width: "110px", borderRadius: "10px", objectFit: 'cover' }} />
                                    </Box>
                                }
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl isRequired isInvalid={!!errors.firstName}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">First Name</FormLabel>
                                    <Input name='firstName' type='text' value={InfluencerData.firstName} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={!!errors.lastName}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Last Name</FormLabel>
                                    <Input name='lastName' type='text' value={InfluencerData.lastName} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={!!errors.talentName}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Talent Name</FormLabel>
                                    <Input name='talentName' type='text' value={InfluencerData.talentName} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.talentName}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl isRequired isInvalid={!!errors.email}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Email</FormLabel>
                                    <Input name='email' type='email' value={InfluencerData.email} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={!!errors.phone}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Phone</FormLabel>
                                    <Input name='phone' type='text' maxLength={10} value={InfluencerData.phone} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.phone}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl isRequired isInvalid={!!errors.address}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Address</FormLabel>
                                    <Input name='address' type='text' value={InfluencerData.address} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.address}</FormErrorMessage>
                                </FormControl>

                                <FormControl isRequired isInvalid={!!errors.birthday}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Birthday</FormLabel>

                                    <Input name='birthday' type='date' value={InfluencerData.birthday} onChange={handleInfluencerDataChange} _focus={{ zIndex: "0" }} />
                                    <FormErrorMessage>{errors.birthday}</FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="64px" zIndex="1" background="#ffffff" px="1">YouTube Username</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon pointerEvents='none' color='gray.500' fontSize='1.2em' children='@' />
                                        <Input name='youtubeUserName' type='text' value={InfluencerData.youtubeUserName} onChange={handleInfluencerDataChange} />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="64px" zIndex="1" background="#ffffff" px="1">Instagram Username</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon pointerEvents='none' color='gray.500' fontSize='1.2em' children='@' />
                                        <Input name='instagramUserName' type='text' value={InfluencerData.instagramUserName} onChange={handleInfluencerDataChange} />
                                    </InputGroup>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="64px" zIndex="1" background="#ffffff" px="1">TikTok Username</FormLabel>
                                    <InputGroup>
                                        <InputLeftAddon pointerEvents='none' color='gray.500' fontSize='1.2em' children='@' />
                                        <Input name='tiktokUserName' type='text' value={InfluencerData.tiktokUserName} onChange={handleInfluencerDataChange} />
                                    </InputGroup>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Partial YouTube Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='partialYoutubeRate' value={InfluencerData.partialYoutubeRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Dedicated YouTube Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='dedicatedYoutubeRate' value={InfluencerData.dedicatedYoutubeRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">YouTube Shorts Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='youtubeShortsRate' value={InfluencerData.youtubeShortsRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">IG Reel Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='igReelRate' value={InfluencerData.igReelRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">IG Story Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='igStoryRate' value={InfluencerData.igStoryRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">IG Feed Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='igFeedRate' value={InfluencerData.igFeedRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">TikTok Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='tiktokRate' value={InfluencerData.tiktokRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Usage Rate</FormLabel>
                                    <NumberInput>
                                        <NumberInputField placeholder='$0,000.00' sx={{ '::placeholder': { fontSize: '13px' } }} name='usageRate' value={InfluencerData.usageRate} onChange={handleInfluencerDataChange} />
                                    </NumberInput>
                                </FormControl>
                            </Flex>
                            <Flex gap={5} mb={6}>


                                <FormControl isRequired isInvalid={!!errors.metaTag} >
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Meta Tags</FormLabel>
                                    {/* <Select name='metaTag' placeholder=' ' value={InfluencerData.metaTag} onChange={handleInfluencerDataChange} >
                                        {
                                            metaTags?.map((tag, index) => (
                                                <option key={index}>{tag}</option>
                                            ))
                                        }
                                    </Select> */}
                                    <InputGroup cursor={"pointer"} onClick={() => setActiveMetaTagDropdown(!activeMetaTagDropdown)}>
                                        <Input mb={2} type='text' sx={{ pointerEvents: "none" }} readOnly />
                                        <InputRightElement>
                                            <BiCaretDown w={3} h={3} color='gray.400' />
                                        </InputRightElement>
                                    </InputGroup>
                                    {activeMetaTagDropdown && (
                                        <Card>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    placeholder="Search Meta Tags"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </InputGroup>
                                            {filteredMetaTags?.length > 0 && (
                                                <Box my={1} maxH={"145px"} overflowY={"auto"} sx={{/* ... your custom styles */ }}>
                                                    {filteredMetaTags?.map((tag, index) => (
                                                        <Text
                                                            px={3}
                                                            py={1}
                                                            fontSize={"sm"}
                                                            _hover={{ bg: "#EDF2F7", cursor: "pointer" }}
                                                            key={index}
                                                            onClick={() => handleMetaTagClick(tag)}
                                                        >
                                                            {tag}
                                                        </Text>
                                                    ))}
                                                </Box>
                                            )}
                                        </Card>
                                    )}

                                    {
                                        selectedMetaTags?.map((metaTag, index) => (
                                            <Tag key={index} mr={2} mb={2} fontWeight={"400"} size={"md"} borderRadius='full' variant='subtle' colorScheme='purple'>
                                                <TagLabel>{metaTag}</TagLabel>
                                                <TagCloseButton onClick={() => handleMetaTagClose(metaTag)} />
                                            </Tag>
                                        ))
                                    }
                                    <FormErrorMessage>{errors.metaTag}</FormErrorMessage>
                                </FormControl>


                                <FormControl isRequired>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Partners</FormLabel>
                                    <InputGroup>
                                        <Input type='text' name='partner' isDisabled onChange={handleInfluencerDataChange} />
                                        {/* {
                                            filterValue && ( */}
                                        <InputRightElement cursor={"pointer"}>
                                            {/* <CloseIcon w={2.5} h={2.5} color='gray.400' /> */}
                                            <SearchIcon w={3} h={3} color='gray.400' />
                                        </InputRightElement>
                                        {/* )
                                        } */}
                                    </InputGroup>
                                    {/* <FormErrorMessage>{errors.metaTag}</FormErrorMessage> */}
                                </FormControl>
                            </Flex>
                            <Flex gap={5}>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Wishlist</FormLabel>
                                    <InputGroup>
                                        <Input mb={2} type='text' value={searchCompanyDomain} onChange={(e) => setSearchCompanyDomain(e.target.value)} />
                                        {
                                            searchCompanyDomain ? (
                                                <InputRightElement cursor={"pointer"}>
                                                    {
                                                        isWishListLoading ?
                                                            <Spinner w={4} h={4} color='gray.400' /> :
                                                            <CloseIcon w={2.5} h={2.5} color='gray.400' onClick={() => setSearchCompanyDomain('')} />
                                                    }
                                                </InputRightElement>
                                            ) : (
                                                <InputRightElement cursor={"pointer"}>
                                                    <SearchIcon w={3} h={3} color='gray.400' />
                                                </InputRightElement>
                                            )
                                        }
                                    </InputGroup>
                                    {
                                        searchCompanyDomain &&
                                        <Card zIndex={1} position="absolute" width="100%" ref={wishListRef}>
                                            {filteredCompanyDomainData?.length > 0 && (
                                                <Box my={1} maxH={"145px"} overflowY={"auto"} sx={{
                                                    "&::-webkit-scrollbar": { width: "4px" },
                                                    "&::-webkit-scrollbar-thumb": { background: "#dddddd", borderRadius: "100vh" },
                                                }}>
                                                    {filteredCompanyDomainData?.map(item => (
                                                        <Text px={3} py={1} fontSize={"sm"} _hover={{ bg: "#EDF2F7", cursor: "pointer" }} key={item?.company_id} onClick={() => handleDomainClick(item?.company_id, item?.company_domain)}>
                                                            {item?.company_domain}
                                                        </Text>
                                                    ))}
                                                </Box>
                                            )}
                                        </Card>
                                    }

                                    {
                                        selectedDomains.domainNames?.map((domain, index) => (
                                            <Tag key={index} mr={2} mb={2} fontWeight={"400"} size={"md"} borderRadius='full' variant='subtle' colorScheme='green'>
                                                <TagLabel>{domain}</TagLabel>
                                                <TagCloseButton onClick={() => handleDomainTagClose(domain)} />
                                            </Tag>
                                        ))
                                    }
                                    {/* <FormErrorMessage>{errors.metaTag}</FormErrorMessage> */}
                                </FormControl>
                                <FormControl>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Blacklist</FormLabel>
                                    <InputGroup>
                                        <Input mb={2} type='text' value={searchMetaTag} onChange={(e) => setSearchMetaTag(e.target.value)} />
                                        {
                                            searchMetaTag ? (
                                                <InputRightElement cursor={"pointer"}>
                                                    {
                                                        isBlackListLoading ?
                                                            <Spinner w={4} h={4} color='gray.400' /> :
                                                            <CloseIcon w={2.5} h={2.5} color='gray.400' onClick={() => setSearchMetaTag('')} />
                                                    }
                                                    {/* <CloseIcon w={2.5} h={2.5} color='gray.400' onClick={() => setSearchMetaTag('')} /> */}
                                                </InputRightElement>
                                            )
                                                :

                                                (
                                                    <InputRightElement cursor={"pointer"}>
                                                        <SearchIcon w={3} h={3} color='gray.400' />
                                                    </InputRightElement>
                                                )
                                        }
                                    </InputGroup>
                                    {
                                        searchMetaTag &&
                                        <Card zIndex={1} position="absolute" width="100%" ref={blackListRef}>
                                            {filteredMetaTagData?.length > 0 && (
                                                <Box my={1} maxH={"145px"} overflowY={"auto"} sx={{
                                                    "&::-webkit-scrollbar": { width: "4px" },
                                                    "&::-webkit-scrollbar-thumb": { background: "#dddddd", borderRadius: "100vh" },
                                                }}>
                                                    {filteredMetaTagData?.map(item => (
                                                        <Text px={3} py={1} fontSize={"sm"} _hover={{ bg: "#EDF2F7", cursor: "pointer" }} key={item?.company_id} onClick={() => handleBlackListMetaTagClick(item?.company_id, item?.company_specialties)}>
                                                            {item?.company_specialties}
                                                        </Text>
                                                    ))}

                                                    {/* {filteredMetaTagData.flatMap(item => item?.company_specialties.split(',').map(specialty => specialty.trim()))
                                                    .map((specialty, index) => (
                                                        <Text px={3} py={1} fontSize={"sm"} _hover={{ bg: "#EDF2F7", cursor: "pointer" }} key={index} onClick={() => handleMetaTagClick(specialty?.company_id, specialty)}>
                                                            {specialty}
                                                        </Text>
                                                    ))} */}
                                                </Box>
                                            )}
                                        </Card>
                                    }

                                    {
                                        selectedBlackListMetaTags.metaTags?.map((metaTag, index) => (
                                            <Tag key={index} mr={2} mb={2} fontWeight={"400"} size={"md"} borderRadius='full' variant='subtle' colorScheme='red'>
                                                <TagLabel>{metaTag}</TagLabel>
                                                <TagCloseButton onClick={() => handleBlackListMetaTagClose(metaTag)} />
                                            </Tag>
                                        ))
                                    }
                                    {/* <FormErrorMessage>{errors.metaTag}</FormErrorMessage> */}
                                </FormControl>
                            </Flex>
                        </Box>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button isLoading={isLoading} spinner={<PulseLoader size={8} color='white' />} loadingText="Creating" spinnerPlacement='end' colorScheme='green' onClick={handleSubmit}>Create</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>


            <CampaignDialogBox
                isOpen={isOpenCampaignDialogBox}
                onClose={() => setIsOpenCampaignDialogBox(false)}
                onNo={handleNo}
                onYes={handleYes}
            />
        </Box>
    )
}

export default InfluencerFormModal