import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Input,
  Flex,
  Text,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CgSmileMouthOpen } from "react-icons/cg";
import { MdOutlineAttachFile, MdFormatColorText } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./emailReplyPopup.css";
import EmojiPicker from "emoji-picker-react";
import { HiTemplate } from "react-icons/hi";

const ReplyModal = ({
  isOpen,
  setIsOpen,
  message,
  isNonGeneral,
  currentEmail,
  getAllMessagesAPI
}) => {
  const [emailContent, setEmailContent] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  console.log("recipientEmail>>>", recipientEmail);
  const [link, setLink] = useState("");
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emailTemplateData, setEmaiTemplateData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState("");
  const [ccMail, setccMail] = useState("");
  const [bccMail, setbccMail] = useState("");
  const toast = useToast();
  const quillRef = useRef(null);
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("userEmail");
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  console.log("message12345678?????", message);

  const filteredMessages = message?.filter(
    (msg) => msg.from_email !== userEmail
  );

  const latestMessage =
    filteredMessages.length > 0
      ? filteredMessages.reduce((latest, current) => {
          return new Date(current.created_at) > new Date(latest.created_at)
            ? current
            : latest;
        })
      : message[0];
  const recipientEmailMatch = message[0].from_email.match(/<([^>]+)>/);
  // const recipientEmailMatch = message[0].from_email;
  const email = recipientEmailMatch ? recipientEmailMatch[1] : latestMessage.to;
  const fullEmail = !isNonGeneral ? message[0].from_email : currentEmail;

  useEffect(() => {
    setRecipientEmail(email);
  }, [latestMessage]);

  const handleEditorChange = (content) => {
    setEmailContent(content);

    // Real-time validation
    if (!content || !content.trim()) {
      setIsEmailEmpty("The email message cannot be empty.");
    } else {
      setIsEmailEmpty("");
    }
  };

  const formatEmailContent = (content) => {
    let formattedContent = content
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "<br/>");

    formattedContent = formattedContent.replace(/<br(?!\/)>/g, "");
    formattedContent = formattedContent.replace(
      /(<br\/>)(?=\s*<br\/>)/g,
      "<br/>"
    );
    formattedContent = formattedContent.trim();
    if (formattedContent.endsWith("<br/>")) {
      formattedContent = formattedContent.slice(0, -5);
    }
    return formattedContent;
  };

  const handleSendEmail = async () => {
    const formattedContent = formatEmailContent(emailContent);
    if (!formattedContent || !formattedContent.trim()) {
      setIsEmailEmpty("The email message cannot be empty.");
      return;
    }

    const formattedCC = ccMail
      ? ccMail
          .split(",")
          .map((email) => email.trim())
          .join(",")
      : "";
    const formattedBCC = bccMail
      ? bccMail
          .split(",")
          .map((email) => email.trim())
          .join(",")
      : "";

    const formData = new FormData();
    formData.append("sender", userEmail);
    formData.append("to", recipientEmail);
    formData.append("cc", formattedCC);
    formData.append("bcc", formattedBCC);
    formData.append("subject", latestMessage.subject);
    formData.append("message_text", formattedContent);
    formData.append("thread_id", message[0].thread_id);
    formData.append("reply_message_id", latestMessage.reply_message_id);
    formData.append("references", latestMessage.references);
    formData.append("in_reply_to", latestMessage.in_reply_to);
    formData.append("full_email", fullEmail);
    formData.append("full_email_to", message[0].from_email);

    if (file) {
      formData.append("file", file);
    }

    if (isEmailEmpty) {
      setIsEmailEmpty("The email message cannot be empty.");
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/send-reply/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast({
        title: "Email sent.",
        description: "Your email reply has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      getAllMessagesAPI();
      setIsOpen(false);
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error sending email.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name);
      toast({
        title: "File uploaded.",
        description: `${uploadedFile.name} has been added.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddEmoji = (emojiData) => {
    const emoji = emojiData.emoji;
    setEmailContent((prev) => prev + emoji);
  };

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const fetchEmailTemp = async () => {
    try {
      // setIsCompanySearchLoading(true);
      //    /get-autorespond-template/
      const response = await fetch(`${BASE_URL}/get-composer-temp/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setEmaiTemplateData(data.data);

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      // setFilteredCompanyData(data?.companies);
      // setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmailTemp();
  }, []);

  const handleShowEmailsTemplates = () => {
    setOpenPopup(true);
  };

  const handleTemplateSelect = (templateName, templateBody) => {
    const updatedContent = templateBody.replace(
      /<strong>(.*?)<\/strong>/g,
      "$1"
    );
    setEmailContent(updatedContent);
    setOpenPopup(false);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const ShowFormattingText = () => {
    const toolbar = document.querySelector(".quill-editor .ql-toolbar");
    if (window.innerWidth <= 768) {
      if (toolbar) {
        const currentDisplay = getComputedStyle(toolbar).display;
        if (currentDisplay === "none") {
          toolbar.style.display = "";
        } else {
          toolbar.style.display = "none";
        }
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.querySelector(".quill-editor .ql-toolbar");
      if (toolbar) {
        // Check window width and toggle toolbar visibility
        if (window.innerWidth <= 768) {
          toolbar.style.display = "none"; // Hide toolbar for small screens
        } else {
          toolbar.style.display = ""; // Show toolbar for larger screens
        }
      }
    };

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="modal-body">
      <Box
        maxW="1400px"
        height="100%"
        mx="auto"
        p={5}
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        backgroundColor="#ffffff"
        position="relative"
      >
        <Stack spacing={4}>
          <Flex align="center">
            <Text w="30px">To:</Text>
            <Input
              variant="flushed"
              value={recipientEmail}
              readOnly={!isEditing}
              onDoubleClick={handleDoubleClick}
              onBlur={handleBlur}
              onChange={(e) => setRecipientEmail(e.target.value)}
            />
          </Flex>

          <Box as="div">
            <div className="custom-quill-container">
              <ReactQuill
                ref={quillRef}
                value={emailContent}
                onChange={handleEditorChange}
                theme="snow"
                modules={modules}
                formats={formats}
                className="quill-editor"
              />
            </div>
            {isEmailEmpty && (
              <span
                className="email_composer_error"
                style={{ color: "red", position: "absolute", bottom: "128px" }}
              >
                Email message is required!
              </span>
            )}
          </Box>
          <Box as="div" mt="54px" position="relative">
            <Flex
              position="absolute"
              top="40px"
              width="100%"
              className="email_composer_footer_area"
            >
              <Box display="flex" alignItems="center">
                <Button
                  colorScheme="blue"
                  size="lg"
                  borderRadius="8"
                  onClick={handleSendEmail}
                  height="40px"
                  width="100px"
                  fontSize="17px"
                >
                  Send
                </Button>
              </Box>

              <Tooltip label="Formatting Text" aria-label="Formatting Text">
                <IconButton
                  ml={2}
                  icon={<MdFormatColorText fontSize="24px" />}
                  aria-label="Formatting Text"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={ShowFormattingText}
                />
              </Tooltip>

              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <Tooltip label="Attach File" aria-label="Attach File">
                <label htmlFor="file-upload">
                  <IconButton
                    as="span"
                    ml={2}
                    icon={<MdOutlineAttachFile fontSize="24px" />}
                    aria-label="Attach File"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                  />
                </label>
              </Tooltip>

              {fileName && (
                <Text ml={3} fontSize="sm" color="gray.500">
                  {fileName}
                </Text>
              )}

              <Popover
                isOpen={showEmojiPicker}
                onClose={() => setShowEmojiPicker(false)}
                closeOnBlur={true}
              >
                <PopoverTrigger>
                  <IconButton
                    ml={2}
                    icon={<CgSmileMouthOpen fontSize="24px" />}
                    aria-label="Insert Emoji"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    onClick={() => setShowEmojiPicker((prev) => !prev)}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <Box p={2}>
                    <EmojiPicker onEmojiClick={handleAddEmoji} />
                  </Box>
                </PopoverContent>
                <Box>
                  <Tooltip label="Email Template" aria-label="Email Template">
                    <HiTemplate
                      className="hitemplate_icon"
                      aria-label="Email Templates"
                      onClick={handleShowEmailsTemplates}
                    />
                  </Tooltip>
                </Box>
                {/* <Button
                  colorScheme="blue"
                  size="sm"
                  borderRadius="8"
                  height="40px"
                  width="200px"
                  fontSize="17px"
                  onClick={handleShowEmailsTemplates}
                >
                </Button> */}
              </Popover>
              <RiDeleteBin6Line
                style={{
                  fontSize: "24px",
                  right: "25px",
                  position: "absolute",
                }}
                onClick={() => setIsOpen(false)}
              />
            </Flex>
          </Box>
        </Stack>
      </Box>
      {openPopup && (
        <Modal isOpen={isOpen} onClose={() => setOpenPopup(false)}>
          <ModalOverlay />
          <ModalContent style={{ height: "500px", overflow: "auto" }}>
            <ModalHeader>Email Templates</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="email-templates-list">
                {emailTemplateData.map((template) => (
                  <div
                    key={template.id}
                    className="template-item"
                    onClick={() =>
                      handleTemplateSelect(template.name, template.body)
                    }
                    style={{
                      cursor: "pointer",
                      margin: "8px 0",
                      padding: "10px",
                      border: "1px solid transparent",
                      borderRadius: "4px",
                      transition: "background-color 0.2s, border 0.2s",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#f0f0f0";
                      e.currentTarget.style.border = "1px solid #ccc";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.border = "1px solid transparent";
                    }}
                  >
                    <span>{template.name}</span>
                    <span style={{ marginLeft: "8px", color: "#888" }}>→</span>
                  </div>
                ))}
              </div>
            </ModalBody>

            {/* <ModalFooter>
              <Button colorScheme="blue" onClick={() => setOpenPopup(false)}>
                Close
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default ReplyModal;
