import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React from 'react'
import { PulseLoader } from 'react-spinners'

const ClockConfirmationModel = ({ isOpen, onClose, isLoading, DeleteFolowUp}) => {
    const handleDelete = ()=>{
        DeleteFolowUp()
        onClose();
    }
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Cancel Follow Up</AlertDialogHeader>
                <AlertDialogBody>Are you sure you want to Cancel Follow up ?</AlertDialogBody>
                <AlertDialogFooter>
                    <Button size={"sm"} onClick={onClose} variant='outline' borderColor='green.500'  border='2px' >No</Button>
                    <Button size={"sm"} colorScheme='red' isLoading={isLoading} onClick={handleDelete}  spinner={<PulseLoader size={8} color='white' />}  loadingText="Deleting" spinnerPlacement='end'  ml={3}   >
                    Yes</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default ClockConfirmationModel