import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { BiSolidUpArrow } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


/* CSS for the tooltip arrow */

const DelayTooltipModal = ({ isOpen, job, fetchSequence, onClose }) => {
  const [delayTime, setDelayTime] = useState(job.delay_time === 0?1:job.delay_time );
  const [isDelayChecked, setIsDelayChecked] = useState(true);
  const toast = useToast();
  

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const navigate = useNavigate()


  const handleDecreaseClick = () => {
    setDelayTime((prev) => (prev - 1 >= 0 ? prev - 1 : 0));
  };
  const handleIncreaseClick = () => {
    setDelayTime((prev) => prev + 1);
  };
  const handleCheckboxChange =(e) =>{
    const isChecked = e.target.checked;
    if (!isChecked){
        setDelayTime(0)
    }
    setIsDelayChecked(e.target.checked)
  }

  const handleSaveDelay= async()=>{
    const payload = {
        job_id : job.id,
        sequence_id : job.sequence_id ,
        type :job.type,
        delay_time : delayTime
    }
      try {
        const response = await fetch(BASE_URL + `/updatejob/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(payload),
        });
        const res = await response.json();
        // setIsUpdating(false)
        // setIsEditable(!isEditable);
        if (response.status === 401) {
          console.error('Unauthorized: User session expired');
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate('/login');
        }
        if (response.ok) {
          toast({
            title: "Delay Time",
            description: "Updated Successfully",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          fetchSequence();
        };
      }
      
       catch (error) {
        
        toast({
          title: "Delay Time",
          description: "Failed to update the job",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        console.error("Error:", error);
      }
  }

  return (
    <>
      {isOpen && (
        <Box
          position="absolute"
          top="40px"
          left="20px"
          // transform="translateX(-50%)"
          bgColor={"white"}
          width={"250px"}
          _after={{
            content: '" "',
            position: "absolute",
            left: "10%",
            top: "-15px",
            borderTop: "none",
            borderRight:"15px solid transparent",
            borderLeft:"15px solid transparent",
            borderBottom:"15px solid rgba(0, 0, 0, 0.1)",
            // filter: "drop-shadow(0 -2px 4px rgba(0, 0, 0, 0.2))"
          }}
          border={'1px solid black '}
          borderColor={'gray.100'}
          boxShadow={"0 2px 8px rgba(0, 0, 0, 0.2)"}
          p={5}
          
          zIndex={100}
        >
    
          <VStack gap={4} align={"start"}>
            <Box>
              <Checkbox defaultChecked isChecked={isDelayChecked}
          onChange={handleCheckboxChange}>
                <Text fontSize={"lg"}>Time delay</Text>
                <Text>Add a time delay</Text>
              </Checkbox>
            </Box>
            {isDelayChecked?
            <Box>
              <Box my={1}>
                <Text as={"b"}>Business days</Text>
              </Box>

              <InputGroup size="lg" >
                <Input pr="4.5rem" w={'150px'} placeholder="" value={delayTime} disabled />
                <InputRightAddon
                  display={"flex"}
                  flexDir={"column"}
                  width="4.5rem"
                  p={0}
                >
                  {/* <Box height={'100%'}> */}
                  <Button
                    isDisabled={delayTime <= 1}
                    h="1.75rem"
                    width={"100%"}
                    size="sm"
                    borderBottom={'1px solid gray'}
                    borderBottomRadius={0}
                    onClick={handleDecreaseClick}
                  >
                    -
                  </Button>
                  <Divider color={'black'} />
                  <Button
                    h="1.75rem"
                    width={"100%"}
                    size="sm"
                    onClick={handleIncreaseClick}
                  >
                    +
                  </Button>
                  {/* </Box> */}
                </InputRightAddon>
              </InputGroup>
            </Box>:
            <Box p={4} border={'1px solid teal'}>
                <Text>This task will be created immediately after above Step</Text> 
            </Box>}

            <HStack>
              <Button onClick={handleSaveDelay} size={"md"} colorScheme="orange" variant={"solid"}>
                Save
              </Button>
              <Button onClick={onClose} size={"md"} colorScheme="orange" variant={"outline"}>
                Cancel
              </Button>
            </HStack>
          </VStack>
        </Box>
      )}
    </>
  );
};

export default DelayTooltipModal;
