import { Box, HStack, Select, Text } from "@chakra-ui/react";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";

export const ApprovedEmailLists = ({ emailList, updateSelectedEmail }) => {
  const handleEmailChange = (e) => {
    updateSelectedEmail(e.target.value);
  };
  return (
    <HStack gap={1} position="relative">
      <Text as={"samp"} position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1" color={"blue"}>Approved emails</Text>
      <Select
      w={"300px"}
        // size={"sm"}
        // width={"30%"}
        onChange={handleEmailChange}
        icon={<MdArrowDropDown />}
        placeholder="select a email"
      >
        {emailList?.map((email, index) => {
          return (
            <option key={index} value={email.email_id}>
              {email.email_id} ({email.campaign_info})
            </option>
          );
        })}
      </Select>
    </HStack>
  );
};
