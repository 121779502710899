// Legend.js
import React from 'react';
import './EventsCalendar.css'; // Make sure to create a CSS file for custom styles

const Legend = () => {
  return (
    <div className="legend">
      <ul>
        <li><span className="dot red"></span>Draft Due</li>
        <li><span className="dot blue"></span>Live Date</li>
        <li><span className="dot green"></span>Payout</li>
      </ul>
    </div>
  );
};

export default Legend;
