import React, { useEffect, useMemo, useState } from "react";
import { PROSPECT_COLUMNS } from "./Columns";
import { useTable, useRowSelect, usePagination } from "react-table";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Checkbox from "./Checkbox";
import { IoMdTrash } from "react-icons/io";
import { TbMailSearch } from "react-icons/tb";
import { format } from "date-fns";
import { AddIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import ProspectFormModal from "../Modal/ProspectFormModal";
import ProspectDeleteDialogBox from "../Modal/ProspectDeleteDialogBox";
import { CreateCampaignModal } from "../Modal/CreateCampaignModal";
// import EmailsModel from "../Modal/EmailsModel";
import ProspectEmailsModel from "../Modal/ProspectEmailsModel";
import { useNavigate } from "react-router-dom";

import {
  fetchAllSequences,
  fetchSequenceById,
} from "../../services/sequenceApi";
import { fetchUserEmails } from "../../services/userEmailsApi";
import { fetchInfluencerMetaData } from "../../services/influencerApi";
import { BsEnvelopePlusFill, BsFillSendPlusFill } from "react-icons/bs";
import axios from "axios";
import { useSelector } from "react-redux";

const ProspectTable = () => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const role = useSelector((state) => state.role) || localStorage.getItem('Role');
  const navigate = useNavigate();

  const toast = useToast();
  const { colorMode } = useColorMode();

  // const [isOpenEmailsModel,setIsOpenEmailsModel] =useState(false);
  const [Roles,setRoles] = useState(role)
  const [isOpenProspectEmailsModel, setIsOpenProspectEmailsModel] =
    useState(false);
  const [EmailsData, setEmailsData] = useState(null);
  const [Final, setFinal] = useState([]);
  const [flat, setFlat] = useState(null);
  const [VerifyEmails, setVerifyEmails] = useState([]);
  //console.log("VerifyEmails ppt>>",VerifyEmails)

  const columns = useMemo(() => PROSPECT_COLUMNS, []);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [prospectData, setProspectData] = useState([]);
  const [isOpenProspectFormModal, setIsOpenProspectFormModal] = useState(false);
  const [isOpenProspectDeleteDialogBox, setIsOpenProspectDeleteDialogBox] =
    useState(false);
  const [isEditing, setIsEditing] = useState(null);

  const [editedRowData, setEditedRowData] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [lastTapTime, setLastTapTime] = useState(0);

  const [filterValue, setFilterValue] = useState("");

  const [sequences, setSequences] = useState([]);
  const [userEmails, setUserEmails] = useState([]);
  const [influencers, setInfluencers] = useState([]);

  const [isCreateCampaignModalOpen, setIsCreateCampaignModalOpen] =
    useState(false);
  // ...
  const [filterSValue, setFilterSValue] = useState('')
  // const [searchResults, setSearchResults] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: prospectData,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <Checkbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );
  //console.log(prospectData,"data")
  console.log(userEmails)

  const fetchProspectData = async () => {
    console.log("fetch prospect data...");
    try {
      console.log("hello in try ");
      
      const response = await fetch(
        BASE_URL +
        (filterValue
          ? `/prospects/?search=${filterValue}&page=${pageIndex + 1
          }&page_size=${pageSize}`
          : `/prospects/?page=${pageIndex + 1
          }&page_size=${pageSize}&ordering=-created_ts`),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      setProspectData(data?.prospects_data);
      setPageCount(data?.paginator?.total_pages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast({
        title: "Fetch Error.",
        description: "Failed to fetch prospect details",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProspectData();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pageIndex, pageSize, filterValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSequences();
      fetchEmails();
      fetchInfluencers();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const fetchInfluencers = async () => {
    try {
      const influencers = await fetchInfluencerMetaData(token);
      setInfluencers(influencers);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const fetchSequences = async () => {
    try {
      const sequences = await fetchAllSequences(token);
      // Handle the list of sequences
      setSequences(sequences);
      return sequences;
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };
  const fetchEmails = async () => {
    try {
      const emails = await fetchUserEmails(token);
      setUserEmails(emails);
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  const handleToggData = async () => {
    //console.log("selectedFlatRow ",selectedFlatRows)
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Optionally, specify the content type
      };
      // Specify the URL of the dummy API
      const apiUrl = `${BASE_URL}/all-prospect-update-emails/`;

      // Prepare the payload
      const payload = {
        selectedFlatRows: selectedFlatRows.map((row) => row.original),
      };
      //console.log("payload>>", payload);

      // Make the POST request using axios
      const response = await axios.post(apiUrl, payload, {
        headers: headers,
      });
      if (response) {
        //console.log("ok200")
        toast({
          title: "Prospects emails update",
          description: "Selected Prospects data update successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        fetchProspectData();
      }
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      // Logging the response from the API
      console.log("Response from the API:", response.data);
    } catch (error) {
      console.error("Error sending data to the API:", error);
    }
  };

  const handleVerifyEmails = async () => {
    const payload = {
      prospect_id: selectedFlatRows[0].original.prospect_id,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Optionally, specify the content type
    };
    try {
      const response = await axios.post(
        `${BASE_URL}/show-verify-email/`,
        payload,
        {
          headers: headers,
        }
      );
      //console.log("response>>>>",response);
      if (response.data.length > 0) {
        //console.log("rsp",response.data)

        setVerifyEmails(response.data);
      } else {
        setVerifyEmails([]);
      }
    } catch (error) {
      if (error.response.data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }
      console.error("error", error);
    }
  };


  const hadnleClickEmails = () => {
    setIsOpenProspectEmailsModel(true);
    setFlat(selectedFlatRows[0].original.prospect_id);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      prospects_data: [
        {
          first_name: selectedFlatRows[0].original.first_name,
          last_name: selectedFlatRows[0].original.last_name,
          company: selectedFlatRows[0].original.company,
        },
      ],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BASE_URL}/prospect-emails/`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
        return response.json();
      })
      .then((result) => {
        //console.log(result,"result");
        setFinal(result);
        handleVerifyEmails();

        // Here, you can now work with `result` as a JavaScript object
        // For example, updating state or processing data
      })
      .catch((error) => console.error(error));
  };
  // console.log("ii>>",isOpenEmailsModel);

  const handleCreateCampaignClick = () => {
    setIsCreateCampaignModalOpen(true);
  };
  const handleDeleteRows = async (selectedFlatRows) => {
    console.log('hello prospect table is here')
    if (selectedFlatRows.length > 1) {
      try {
        setIsLoading(true);
        const prospectIds = selectedFlatRows.map(
          (row) => row?.original?.prospect_id
        );
        // console.log(prospectIds);

        const response = await fetch(BASE_URL + "/prospect/", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ prospect_ids: prospectIds }),
        });

        if (response.status === 401) {
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          navigate("/login");
        }
      const data = await response.json();
      console.log('data',data);
      
        if (response.ok) {
          console.log('hello in delete');
          
          setIsLoading(false);
          setProspectData((prevData) =>
            prevData.filter(
              (prospect) => !prospectIds.includes(prospect.prospect_id)
            )
          );
     
          fetchProspectData();

          toast({
            title: "Prospects Deletion Status",
            description: `${data.message}`,
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });

          setIsOpenProspectDeleteDialogBox(false);
        } else {
          setIsLoading(false);

          toast({
            title: "Deletion Failed",
            description: "Failed to delete prospects details.",
            status: "error",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete prospects details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error:", error);
      }
    } else {
      try {
        setIsLoading(true);
        for (const row of selectedFlatRows) {
          const prospectId = row?.original?.prospect_id;

          const response = await fetch(BASE_URL + `/prospects/${prospectId}/`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
           const data = await response.json();
           console.log('data from 1 ', data);
           
          if (response.status === 401) {
            console.error("Unauthorized: User session expired");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            navigate("/login");
          }

          if (response.ok) {
            setIsLoading(false);
            setProspectData((prevData) =>
              prevData.filter((prospect) => prospect.prospect_id !== prospectId)
            );
            fetchProspectData()
            toast({
              title: "Prospect Deletion Status",
              description: `${data.message}`,
              status: "success",
              position: "bottom",
              duration: 3000,
              isClosable: true,
            });
            setIsOpenProspectDeleteDialogBox(false);
          }

          else {
            setIsLoading(false);

            toast({
              title: "Deletion Failed",
              description: "Failed to delete prospect details.",
              status: "error",
              position: "bottom",
              duration: 3000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete prospect details.",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error:", error);
      }
    }
  };
  const handleCellBlur = () => {
    // Auto-save when clicking outside the cell
    if (isEditing) {
      setIsEditing(null);
      setEditingCell(null);
      handleSaveRow();
    }
  };

  const handleCellClick = (row, cell) => {
    // Check if the cell is editable

    if (cell.column) {
      setIsEditing(row.original.prospect_id);
      setEditedRowData([row.original]);
      setEditingCell(cell.column.id);
    }
  };
  const handleEditRow = () => {
    const selectedEditableProspects = selectedFlatRows?.map(
      (row) => row.original.prospect_id
    );
    setIsEditing(selectedEditableProspects);
    // setIsEditing(selectedFlatRows[0]?.original?.prospect_id);
    // setEditedRowData(selectedFlatRows[0]?.original);
    const selectedRowData = selectedFlatRows?.map((row) => row.original);
    setEditedRowData(selectedRowData);
  };

  useEffect(() => {
    // handleEditRow()
    setIsEditing(null);
    setEditedRowData(null);
  }, []);

  const handleSaveRow = async () => {
    // const prospectId = editedRowData?.prospect_id;
    const payload = {
      prospect_data: editedRowData,
    };
    //    //prospects/${prospectId}/
    try {
      const response = await fetch(BASE_URL + `/updateprospects/`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
      }

      if (response.ok) {
        setIsEditing(null);
        setEditedRowData(null);
        fetchProspectData();
        toast({
          title: "Prospect Updated",
          description: "Prospect updated successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to update prospect details.");
      }
    } catch (error) {
      toast({
        title: "Update Failed",
        description: "Failed to update prospect details.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.error("Error:", error);
    }
  };

  console.log(selectedFlatRows);



  // ......sequence api ..
  const fetchData = async (searchQuery) => {
    try {
      const response = await fetch(BASE_URL + `/allsequence/?name=${searchQuery}`, {

        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log("data", data['Seqeunce'])

      // console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  // ...
  const handleInputChange = (e) => {
    const { value } = e.target
    setFilterSValue(value)
    fetchData(value);
  }
  // console.log("filterSvalue",filterSValue)
  return (
    <Box px={5} py={3}>
      {/* <EmailsModel
       isOpen={isOpenEmailsModel}
       onClose={() => { 
        setIsOpenEmailsModel(false);
        setFinal([]);
       
          //  fetchCompanyData();
       }}
       EmailsData={Final}
       prospect_id={flat}
       SaveData= {fetchProspectData}
       
        
  
       /> */}

      <ProspectEmailsModel
        isOpen={isOpenProspectEmailsModel}
        onClose={() => {
          setIsOpenProspectEmailsModel(false);
          setFinal([]);

          //  fetchCompanyData();
        }}
        EmailsData={Final}
        prospect_id={flat}
        SaveData={fetchProspectData}
        VerifyEmails={VerifyEmails}
        hadnleClickEmails={hadnleClickEmails}
        handleVerifyEmails={handleVerifyEmails}
      />

      <SkeletonText
        noOfLines={20}
        spacing="2"
        skeletonHeight="6"
        isLoaded={!isLoading}
      >
        {selectedFlatRows.length > 0 ? (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>{selectedFlatRows.length} selected</Box>
            <Flex alignItems={"center"} gap={3}>
              <Button
                onClick={handleCreateCampaignClick}
                size={"sm"}
                colorScheme="blue"
                variant="solid"
              >
                <BsFillSendPlusFill size={20} color="white" />
              </Button>

              <Button
                onClick={handleToggData}
                size={"sm"}
                colorScheme="blue"
                variant="solid"
              >
                <BsEnvelopePlusFill size={20} color="white" />
              </Button>
              {selectedFlatRows.length === 1 ? (
                <Button
                  onClick={hadnleClickEmails}
                  size={"sm"}
                  colorScheme="blue"
                  variant="solid"
                >
                  <TbMailSearch size={25} color="white" />
                </Button>
              ) : (
                " "
              )}
              {/* {isEditing !== null ? (
                <Button onClick={handleSaveRow} size={"sm"} colorScheme="blue">
                  Save
                </Button>
              ) : (
                <Button
                  onClick={handleEditRow}
                  size={"sm"}
                  colorScheme="yellow"
                >
                  Edit
                </Button>
              )} */}
              {isEditing && (
                <Button onClick={handleSaveRow} size={"sm"} colorScheme="blue">
                  Save
                </Button>
              )}

              {/* <Box onClick={() => handleDeleteRows(selectedFlatRows)} bg={"none"} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}><IoMdTrash size={20} color='red' /></Box> */}
              <Button
                onClick={() => setIsOpenProspectDeleteDialogBox(true)}
                // bg={"none"}
                size={"sm"}
                colorScheme="red"
                variant="solid"
              // _hover={{
              //   borderRadius: "100vh",
              //   bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
              //   cursor: "pointer",
              // }}
              // p={"8px"}
              >
                {/* <IoMdTrash size={20} color="red" /> */}
                <IoMdTrash size={20} color="white" />
              </Button>
            </Flex>
          </Flex>
        ) : (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={3}>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search prospect..."
                  width={"350px"}
                  borderRadius={"100vh"}
                  value={filterValue}
                  onChange={(e) => {
                    setFilterValue(e.target.value);
                    gotoPage(0);
                  }}
                />
                {filterValue && (
                  <InputRightElement cursor={"pointer"}>
                    <CloseIcon
                      w={3}
                      h={3}
                      color="gray.400"
                      onClick={() => setFilterValue("")}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </Box>
            {/* <Button onClick={() => setIsOpenProspectFormModal(true)} size={"sm"} leftIcon={<AddIcon />} colorScheme='blue' variant='solid'>ADD</Button> */}
          </Flex>
        )}
        <Box
          fontSize={14}
          mb={1}
          boxShadow="xs"
          h={"calc(100vh - 180px)"}
          w={"calc(100vw - 290px)"}
          rounded={"md"}
          sx={{
            position: "relative",
          }}
        >
          <TableContainer
            {...getTableProps()}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              "&::-webkit-scrollbar": { height: "10px", width: "0px" },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#3182ce",
                borderRadius: "10px",
              },
            }}
          >
            <Table variant="simple" width={"100%"}>
              <Thead bg={"green.400"}   className='fix-header'>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th {...column.getHeaderProps()} color={"#FFFFFF"}>
                        <Flex alignItems={"center"}>
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  const isRowEditing = isEditing === row?.original?.prospect_id;
                  return (
                    <Tr
                      {...row.getRowProps()}
                      _hover={{
                        bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                        boxShadow:
                          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                      }}
                    >
                      {row.cells.map((cell) => {
                        // const isRowEditing = isEditing?.includes(row?.original?.prospect_id);
                        const isCellEditing =
                          isRowEditing && editingCell === cell.column.id;

                        return (
                          <Td
                            onTouchEnd={(e) => {
                              // Check if the touch event is a double-tap
                              if (e.timeStamp - lastTapTime < 600) {
                                handleCellClick(row, cell);
                              }
                              setLastTapTime(e.timeStamp);
                            }}
                            onDoubleClick={() => handleCellClick(row, cell)} // Handle cell click
                            {...cell.getCellProps()}
                          >
                            {cell.column.id === "selection" ? (
                              <Checkbox {...row.getToggleRowSelectedProps()} />
                            ) : cell.column.Header === "Created At" ? (
                              format(
                                new Date(row.original.created_ts),
                                "MM/dd/yyyy HH:mm:ss"
                              )
                            ) : isCellEditing ? (
                              <Textarea
                                style={{ resize: "both", minWidth: "250px" }}
                                // w="250px"
                                type="text"
                                value={row.values[cell.column.id]}
                                onChange={(e) => {
                                  const fieldName = cell.column.id;
                                  const updatedValue = e.target.value;
                                  const updatedProspectId =
                                    row.original.prospect_id;
                                  row.values[fieldName] = updatedValue;
                                  // Create a new array with updated data for the corresponding prospect
                                  const updatedEditedRowData =
                                    editedRowData?.map((rowData) => {
                                      if (
                                        rowData.prospect_id ===
                                        updatedProspectId
                                      ) {
                                        return {
                                          ...rowData,
                                          [fieldName]: updatedValue,
                                        };
                                      }
                                      return rowData;
                                    });

                                  setEditedRowData(updatedEditedRowData);
                                }}
                                onBlur={() => handleCellBlur()}
                              />
                            ) : (
                              cell.render("Cell")
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Flex>
            Page&nbsp;
            <Text fontWeight={500}>
              {pageIndex + 1} of {pageCount}
            </Text>
          </Flex>
          <Flex alignItems={"center"} gap={3}>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronLeft
                size={24}
                onClick={() => previousPage()}
                disabled={pageIndex === 0}
              />
            </Box>
            <Box
              _hover={{
                bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                cursor: "pointer",
                borderRadius: "100vh",
              }}
              p={"7px"}
            >
              <GoChevronRight
                size={24}
                onClick={() => nextPage()}
                disabled={pageIndex === pageCount - 1}
              />
            </Box>
          </Flex>
        </Flex>
      </SkeletonText>

      <CreateCampaignModal
        sequences={sequences}
        filterValue={filterSValue}
        handleInputChange={handleInputChange}
        emailList={userEmails}
        influencers={influencers}
        selectedPropspects={selectedFlatRows}
        isOpen={isCreateCampaignModalOpen}
        setIsCreateCampaignModalOpen ={setIsCreateCampaignModalOpen}
        // onClose={() => { setIsCreateCampaignModalOpen(false); }}
      />
      <ProspectDeleteDialogBox
        isOpen={isOpenProspectDeleteDialogBox}
        onClose={() => {
          setIsOpenProspectDeleteDialogBox(false);
        }}
        isLoading={isLoading}
        selectedFlatRows={selectedFlatRows}
        handleDeleteRows={handleDeleteRows}
      />
    </Box>
  );
};

export default ProspectTable;
