import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
  Text,
  Input,
  HStack,
  Box,
  Divider,
  Textarea,
  Select,
  useToast,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "./quill.css";
// import { modules, formats } from "../TemplateEditor/quillConstants";
import { CustomEditor } from "../TemplateEditor/CustomEditor";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";


export const TemplateEditorModal = ({
  isDisabled,
  isOpen,
  handleAddJobs,
  isCreateNewTemplate,
  fetchTemplates,
  fetchSequence,
  template,
  onClose,
}) => {
  // console.log(template);
  const toast = useToast();
  const role = useSelector((state) => state.role) || localStorage.getItem('Role');
  const [templateId, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [personalizeList, setPersonalizeList] = useState([]);
  console.log('personlaizedlist ',personalizeList);
  
  const [selectPersonalization, setSelectedPersonalization] = useState();
  const textAreaRef = useRef(null);
  const subjectRef = useRef(null);
  const [activeInputRef, setActiveInputRef] = useState(null);
 const [roles,setRoles] = useState(role);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

 

  console.log('templateId>>>',templateId);
  console.log('templateName',templateName);
  console.log('templateBody',templateBody);
  console.log('personalizeList',personalizeList);
  console.log('templateSubject',templateSubject);
  
  


  const getPersonolization = async () => {
    try {
      const response = await fetch(BASE_URL + `/getpersonalization/`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }});
      const variables = await response.json();

      if(variables.code === 'token_not_valid'){    
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      };
      if (response.ok) {
        setPersonalizeList(variables.dynamic_variable);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTemplateSave = async () => {
    let payload = {};
    let method = "PUT";
    let endpoint = "";
    if (isCreateNewTemplate) {
      endpoint = "addtemplate";
      method = "POST";
      payload = {
        name: templateName,
        body: templateBody,
        subject: templateSubject,
      };
    } else {
      endpoint = "updatetemplate";
      method = "PUT";
      payload = {
        template_id: templateId,
        name: templateName,
        body: templateBody,
        subject: templateSubject,
      };
    }

    console.log(payload);
    // setIsUpdating(true)
    try {
      const response = await fetch(BASE_URL + `/${endpoint}/`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const res = await response.json();
      // setIsUpdating(false)
      // setIsEditable(!isEditable);
      if (response.status === 401) {
        console.error('Unauthorized: User session expired');
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
       };
      if (response.ok) {
        toast({
          title: "Template",
          description: "Successfully saved",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        if (fetchSequence) {
          fetchSequence();
        }
        if (fetchTemplates) {
          fetchTemplates();
        }
        if (handleAddJobs) {
          handleAddJobs(res.template_id);
        }
        onClose();
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Fetch Error.",
        description: "Failed to save the template",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }

    // fetchData();
  };

  // const insertVariable = (variable) => {
  //   // const wrappedVariable = `<span class="variable-${variable}">{${variable}}</span>`;
  //   if (activeInputRef === textAreaRef) {
  //     const cursorPosition = textAreaRef.current.selectionStart;
  //     const text = templateBody;
  //     const newText =
  //       text.slice(0, cursorPosition) +
  //       `{${variable}}` +
  //       text.slice(cursorPosition);
  //     setTemplateBody(newText);

  //     // Move the cursor after the inserted text
  //     textAreaRef.current.selectionStart = cursorPosition + variable.length + 2;
  //     textAreaRef.current.selectionEnd = cursorPosition + variable.length + 2;
  //   } else if (activeInputRef === subjectRef) {
  //     const cursorPosition = subjectRef.current.selectionStart;
  //     const text = templateSubject;
  //     const newText =
  //       text.slice(0, cursorPosition) +
  //       `{${variable}}` +
  //       text.slice(cursorPosition);
  //     setTemplateSubject(newText);

  //     // Move the cursor after the inserted text
  //     subjectRef.current.selectionStart = cursorPosition + variable.length + 2;
  //     subjectRef.current.selectionEnd = cursorPosition + variable.length + 2;
  //   }
  // };

  useEffect(() => {
    getPersonolization();
  }, []);

  useEffect(() => {
    

    if (template && !isCreateNewTemplate) {
      console.log("editor Modal useffect runn",template );
      setTemplateId(template.id);
      setTemplateName(template.name);
      setTemplateBody(template.body);
      setTemplateSubject(template.subject);
    } else if (isCreateNewTemplate) {
      setTemplateName("");
      setTemplateBody("");
      setTemplateSubject("");
    }
  }, [template, isCreateNewTemplate]);

  return (
    <>
      <Modal size={"5xl"} isOpen={isOpen} onClose={onClose} isCentered scrollBehavior={'outside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={2}
            px={5}
            alignContent={"center"}
            bgGradient={"linear(to-l, #2ba62f, #a1eb34)"}
            color={'white'}
          >
            Create new template
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box border={"1px"} borderColor={"gray.200"}>
              <Flex
                p={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <HStack gap={5} width={"50%"} justifyContent={"space-between"}>
                  <Text as={"b"}>Name:</Text>
                  <Input
                    isDisabled={isDisabled}
                    type="text"
                    variant="flushed"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    placeholder="template name"
                  ></Input>
                </HStack>
{roles === 'Admin' &&  roles ==='Influencer' &&
(
                <HStack mx={2}>

                  <Text as={"b"}>Owner:</Text>

                  <Text>Michael</Text>
                </HStack>
)

}
              </Flex>
              <Divider />
              {/* <Flex
                p={1}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <HStack gap={5} w={"100%"} justifyContent={"space-between"}>
                  <Text as={"b"}>Subject:</Text>
                  <Input
                    isDisabled={isDisabled}
                    ref={subjectRef}
                    type="text"
                    variant="flushed"
                    value={templateSubject}
                    onChange={(e) => setTemplateSubject(e.target.value)}
                    placeholder="Subject"
                    onFocus={handleSubjectInputFocus}
                  ></Input>
                </HStack>
              </Flex>
              <Divider /> */}
              <Box>
                <CustomEditor
                  isDisabled={isDisabled}
                  // activeRef={activeInputRef}
                  // subjectRef={subjectRef}
                  templateSubject={templateSubject}
                  setTemplateSubject={setTemplateSubject}
                  templateBody={templateBody}
                  setTemplateBody={setTemplateBody}
                  personalizeList={personalizeList}
                />
               
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              colorScheme="orange"
              variant={"outline"}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isDisabled={isDisabled}
              colorScheme="green"
              variant={"solid"}
              onClick={handleTemplateSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
