import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  Input,
  Divider,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useNotification } from "../../services/NotificationContext";

const stripHtmlTags = (html) => {
  return html
    .replace(/<br\s*\/?>/g, "\n")
    .replace(/<[^>]*>/g, "")
    .replace(/&nbsp;/g, " ")
    .replace(/&bsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
};

const convertToHtmlFormat = (text) => {
  let formattedText = text.replace(/\n/g, "<br>").trim();
  formattedText = formattedText.replace(/\((.*?)\)/g, "<span>($1)</span>");
  formattedText = formattedText.replace(/\{(.*?)\}/g, "<span>{$1}</span>");
  return formattedText;
};

const EditTemplateModel = ({
  isOpen,
  onClose,
  templateId,
  name,
  body,
  fetchEmailTemp,
  fetchTemplates,
  setIsOpenTemplate,
  isTemplateData,
}) => {
  const [plainTextBody, setPlainTextBody] = useState(stripHtmlTags(body));
  const [currentName, setCurrentName] = useState(name);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token"); 
  const {  handleEmailRead } = useNotification();

  const toast = useToast();
  const handleChange = (event) => {
    setPlainTextBody(event.target.value);
  };



  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };

  const handleSave = async () => {
    const formattedBody = convertToHtmlFormat(plainTextBody);
    let url;
    let method;
    let bodyContent;

    if (isTemplateData) {
      url = `${BASE_URL}/get-composer-temp/`;
      method = "PUT";
      bodyContent = {
        id: templateId,
        body: formattedBody,
        name: currentName,
      };
    } else {
      url = `${BASE_URL}/get-autorespond-template/`;
      method = "PATCH";
      bodyContent = {
        temp_id: templateId,
        body: formattedBody,
        name: currentName,
      };
    }

    try {
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify(bodyContent),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      handleEmailRead();
      if (
        data.message === "Template updated successfully" ||
        data.message === "Data updated successfully"
      ) {
        setIsOpenTemplate(false);
        toast({
          title: "Data updated successfully",
          description: "Data updated successfully",
          status: "success",
          position: "bottom",
          duration: 2000,
          isClosable: true,
        });
        await fetchTemplates();
        await fetchEmailTemp();
        // onClose();
      } else {
        throw new Error("Data update failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Data update not successful",
        description: error.message || "Data update not successful",
        status: "error",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
      // onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={() => setIsOpenTemplate(false)}>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: "700px", height: "500px" }}>
          <ModalHeader>Edit Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex>
              <Box>Name</Box>
              <Input
              style={{
                 margin : "-8px 6px"
              }}
                type="text"
                value={currentName}
                onChange={handleNameChange}
              ></Input>
            </Flex>
            <Divider />
            <Box mt={4}>
              <Textarea
                value={plainTextBody}
                width="100%"
                height="250px"
                onChange={handleChange}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => setIsOpenTemplate(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditTemplateModel;
