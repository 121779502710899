import { Box, Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RxUpdate } from "react-icons/rx";
import { useNavigate } from 'react-router-dom';


export const SequenceMeta = ({isDisabled, sequence, fetchSequences }) => {
  // console.log(sequence);
  const [sequenceName, setSequenceName] = useState(sequence.name);
  const [sequenceDescription, setSequenceDescription] = useState(sequence.description);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const toast = useToast();
  const navigate = useNavigate()


  useEffect(()=>{
    setSequenceName(sequence.name);
    setSequenceDescription(sequence.description);
  },[sequence])

  const handleSequenceChange = async () => {
    // console.log(sequence);
    const payload = {
      sequence_id: sequence.id,
      name: sequenceName,
      description: sequenceDescription,
    };
    try {
      const response = await fetch(BASE_URL + `/updatesequence/`, {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if(data.code === 'token_not_valid'){                
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      };
      if (response.ok) {
        if(fetchSequences){
          fetchSequences();
        }
          
        toast({
          title: "Sequence",
          description: data.message,
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      // setIsLoading(false);
      toast({
        title: "Sequence",
        description: "Failed to save.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      console.error("Error:", error);
    }
  };
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} gap={3}>
      <Flex alignItems={"center"} gap={2}>
        <Box position="relative">
          <Text fontWeight={"500"} position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1" color={"blue"}>Sequence Name</Text>
          <Input
            // w={"xs"}
            w={"300px"}
            value={sequenceName}
            onChange={(e) => setSequenceName(e.target.value)}
            _focus={{ zIndex: "0" }}
          ></Input>
        </Box>
        <Box position="relative">
          <Text fontWeight={"500"} position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1" color={"blue"}>Sequence Description</Text>
          <Input
          //   w={"xs"}
          w={"300px"}
            value={sequenceDescription}
            onChange={(e) => setSequenceDescription(e.target.value)}
            _focus={{ zIndex: "0" }}
          ></Input>
        </Box>
      </Flex>
      <Button size={"sm"} isDisabled={isDisabled} onClick={handleSequenceChange} colorScheme="purple">
        Save
      </Button>
    </Flex>
  );
};
