import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  Select,
  InputGroup,
  InputRightElement,
  Text,
  Link,
  Image

} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import React, { useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { FaUserPlus } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import "../Table/datepicker.css";
import { useNavigate } from "react-router-dom";
import CampaignDialogBox from "./CampaignDialogBox";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { IoImages } from 'react-icons/io5';
import { useEffect } from "react";
import { HStack } from "@chakra-ui/react";

const UserFormModal = ({ isOpen, onClose, fetchUserData,influencers }) => {
  // const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const toast = useToast();
  const imageInputRef = useRef(null);

  const [isOpenCampaignDialogBox, setIsOpenCampaignDialogBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const[searchText,setSearchText] =useState('');
  const [selectedInfluencerValue, setSelectedInfluencerValue] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState({ influencersName: "", influencerId: "" });
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  console.log('selectedInfluencer', selectedInfluencer,selectedInfluencerValue);
  

  const handleClick = () => setShowPassword(!showPassword);


  const [userData, setUserData] = useState({
    profile_picture: "",
    first_name: "",
    last_name: "",
    account_type: "",
    email: "",
    password: "",
    // influencer_ids:[]
  });
  const [errors, setErrors] = useState({
    profile_picture_uploaded:"",
    profile_picture: "",
    first_name: "",
    last_name: "",
    account_type: "",
    email: "",
    password: "",
    influencer_ids: ""
  });

  console.log('userdataaccounttype>>`',userData.account_type)
  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
    imageInputRef.current.value = null;
    setErrors((prev) => ({ ...prev, profile_picture_uploaded: "" }));
}

  const handleYes = () => {
    setIsOpenCampaignDialogBox(false);
    handleSubmittingData(true);
  };

  const handleNo = () => {
    setIsOpenCampaignDialogBox(false);
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSubmit = () => {
    setIsOpenCampaignDialogBox(true);
  };


  const handleSubmittingData = () => {
    if (
      (!userData.profile_picture && !selectedImage) ||
      !userData.first_name ||
      !userData.last_name ||
      !userData.account_type ||
      !userData.email ||
      !userData.password ||
      ((userData.account_type == 'Influencer') &&
         !selectedInfluencerValue)
    )
     
    
    {
      if (!userData.profile_picture && !selectedImage) {
        setErrors((prev) => ({
          ...prev,
          profile_picture: "Profile Picture is required.",
        }));

        setErrors((prev) => ({
          ...prev,
          profile_picture_uploaded: "Profile Picture is required.",
        }));

      }
      if (!userData.first_name) {
        setErrors((prev) => ({
          ...prev,
          first_name: "First name is required.",
        }));
      }
      if (!userData.last_name) {
        setErrors((prev) => ({ ...prev, last_name: "Last name is required." }));
      }
      if (!userData.account_type) {
        setErrors((prev) => ({
          ...prev,
          account_type: "Account Type is required.",
        }));
      }
      if (!userData.email) {
        setErrors((prev) => ({ ...prev, email: "Email is required." }));
      }
      if (!userData.password) {
        setErrors((prev) => ({ ...prev, password: "Password is required." }));
      }
      if (
        (userData.account_type === 'Influencer' ) &&
        !selectedInfluencerValue
        
      ) {
        setErrors((prev) => ({ ...prev, influencer_ids: "Talent Name is required." }));
      }
      return;
    }
  //   if (userData.account_type !== 'Admin' && !selectedInfluencerValue) {
  //     // Perform some action, like setting an error or stopping the form submission
  //     // setErrors((prev) => ({ ...prev, influencer_ids: "Talent Name is required." }));
  //     return; // Stop further execution if the condition is met
  // }
  
    setIsLoading(true);
  console.log('selectedimfluenecer',selectedInfluencerValue);
  
    // Create FormData object and append fields
    const formData = new FormData();
    formData.append('profile_picture_url', userData.profile_picture);
    formData.append('first_name', userData.first_name);
    formData.append('last_name', userData.last_name);
    formData.append('account_type', userData.account_type);
    formData.append('email', userData.email);
    formData.append('password', userData.password);
    {(userData.account_type == 'Influencer' ) &&

    formData.append('influencer_ids', selectedInfluencerValue || null);

    }
    if (selectedImage) {
      formData.append('profile_picture', selectedImage);
    }
  
    fetch(BASE_URL + "/register/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
    .then((response) => {
      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      return response.json();
    })
    .then((data) => {
      setIsLoading(false);
  
      if (data.message) {
        onClose();
        fetchUserData();
        toast({
          title: "Onboarding successful",
          description: data.message,
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
  
        setTimeout(() => {
          setUserData({
            profile_picture: "",
            first_name: "",
            last_name: "",
            account_type: "",
            email: "",
            password: "",
          });
          setSelectedImage(null);
        }, 3000);
      } else {
        onClose();
        toast({
          title: "Onboarding Failed",
          description: "Failed to Onboarding",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
      }
    })
    .catch((error) => {
      setIsLoading(false);
  
      toast({
        title: "Onboarding Error",
        description: "Failed to Onboarding",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
  
      console.error("Error:", error);
    });
  };

  const closeUserForm=()=>{
    console.log('hi>>');
    
onClose()
setUserData({
  profile_picture: "",
  first_name: "",
  last_name: "",
  account_type: "",
  email: "",
  password: "",
});
setSelectedImage(null);
setSelectedInfluencerValue([]);
setSelectedInfluencer({ ...selectedInfluencer, influencersName: '', influencerId: '' });


  }

  const handleSelectedInfluencer = (influencerId, influencerName) => {
    setSelectedInfluencerValue([influencerId]);

    setSelectedInfluencer({ ...selectedInfluencer, influencersName: influencerName, influencerId: influencerId })
    setSearchText('');
  };

  const handleToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSearchText("");
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.custom-dropdown')) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <Drawer size={"lg"} isOpen={isOpen} placement="right" onClose={closeUserForm}>
      <Box height="80vh">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"#ffffff"} />
          <DrawerHeader
            mb={3}
            bg={"linear-gradient(to right, #00AA45, #32bb6a, #66cc8f)"}
            color={"#ffffff"}
            fontWeight={"500"}
            fontSize={"2xl"}
          >
            <Flex alignItems={"center"} gap={2}>
              <FaUserPlus size={28} />
              New User
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Box>
            <Flex gap={5} mb={6}>
                                <FormControl maxW={"60%"} border={"2px dashed blue"} borderRadius={"10px"} p={"30px 100px"} isInvalid={!!errors.profile_picture_uploaded && !!errors.profile_picture} isDisabled={userData.profile_picture}>
                                    <FormLabel fontSize="sm" position="absolute" top="-3" left="3" zIndex="1" background="#ffffff" px="1">Profile Picture</FormLabel>
                                    <Flex flexDirection={"column"} alignItems={"center"} color={"#6B6F7A"}>
                                        <IoImages size={25} />
                                        <Text fontSize={"sm"} mt={"3px"}>
                                            <Link textDecoration={"underline"} color={"blue"} onClick={() => imageInputRef.current.click()}>Click to upload an image</Link>
                                        </Text>
                                        {
                                            selectedImage &&
                                           
                                                <Flex alignItems={"center"} justifyContent={"center"}>
                                                    <Text>{selectedImage?.name}</Text>
                                                    {/* <RxCross2 size={16} color={"red"} cursor={"pointer"} onClick={() => setSelectedImage(null)}  />   */}
                                                </Flex>
                                               
                                        }
                                        <Input type='file' name='profilePicture' style={{ display: 'none' }} onChange={handleImageChange} ref={imageInputRef} accept='image/*' />
                                    </Flex>
                                    <FormErrorMessage>{errors.profile_picture_uploaded}</FormErrorMessage>
                                </FormControl>
                                {
                                    profilePictureURL &&
                                    <Box position={"relative"}>
                                        {/* <RxCross2 size={20} color={"#ffffff"} cursor={"pointer"} style={{ 
                                            position: 'absolute', 
                                            right: '0', 
                                            background: '#00AA45',
                                            borderTopRightRadius: "10px"
                                        }} onClick={() => setSelectedImage(null)}  />   */}
                                        <Image src={profilePictureURL} alt='image' style={{ height: "110px", width: "110px", borderRadius: "10px", objectFit: 'cover' }} />
                                    </Box>
                                }
                            </Flex>
                            or
            <Flex gap={5} mb={10} mt={5}>
            <FormControl  isInvalid={!!errors.profile_picture && !!errors.profile_picture_uploaded} isDisabled={selectedImage}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Profile Url
                  </FormLabel>
                  <Input
                    name="profile_picture"
                    type="text"
                    value={userData.profile_picture}
                    onChange={handleUserDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.profile_picture}</FormErrorMessage>
                </FormControl>
                </Flex>
              <Flex gap={5} mb={10}>
                <FormControl isRequired isInvalid={!!errors.first_name}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    First Name
                  </FormLabel>
                  <Input
                    name="first_name"
                    type="text"
                    value={userData.first_name}
                    onChange={handleUserDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors.last_name}>
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Last Name
                  </FormLabel>
                  <Input
                    name="last_name"
                    type="text"
                    value={userData.last_name}
                    onChange={handleUserDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                </FormControl>
                </Flex>
              <div className="tal-acc-both"> 
                <div></div>
                <Flex gap={5} mb={10} className="acc_list">
                <FormControl className="acc-type" isRequired isInvalid={!!errors.account_type}>
                <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Account Type
                  </FormLabel>
                  <Select
                    name="account_type"
                    className="custom-dropdown"
                    fontSize="sm"
                    onChange={handleUserDataChange}
                    icon={<MdArrowDropDown />}
                    value={userData.account_type}
                    placeholder="Select Account Type"
                    _focus={{ zIndex: "0" }}
                  >
                    <option key="Admin" value="Admin">
                      Admin
                    </option>
                    <option key="Creator Manager" value="Creator Manager">
                      Creator Manager
                    </option>
                    <option key="Influencer" value = "Influencer">
                      Influencer
                    </option>
                  </Select>
                  <FormErrorMessage>{errors.account_type}</FormErrorMessage>
                </FormControl>

            {/* <div className="side_slection"> */}
                {(userData.account_type == 'Influencer' )  &&

                <FormControl className="side_slection" isRequired isInvalid={!!errors.talent}>
                  <FormLabel className="lab-talent" 
                                      
                  >Talent name</FormLabel>
                <HStack gap={1} position="relative" className={`custom-dropdown ${isDropdownOpen ? 'open' : ''}`}>
                  
                <Text as={"samp"} position="absolute" left="3" zIndex="1" background="#ffffff" px="2" color={"blue"} boxSizing={"borderBox"} >
                  {/* Talent Name */}
                </Text>

                <div className="nice-select">
                   <div className="select-box  remove_space" onClick={handleToggle}>
                    {selectedInfluencerValue.length === 0
                      ?
                      <div className="select-talent-name">
                        <span>Select Talent name</span>
                        <MdArrowDropDown color="gray.300" />
                      </div>

                      :

                      <div className="select-talent-name">
                        {selectedInfluencerValue.map((value) => {
                          return influencers.find((influencer) => influencer.id === value)?.name
                        })}
                        <MdArrowDropDown color="gray.300" fontSize='22px' />
                      </div>
                    }

                    {isDropdownOpen && (
                      <div className="talent-dropdown">
                        <div className="nice-select-search-box">
                          <InputGroup>
                            <Input
                              type="text"
                              placeholder="Search a Talent..."
                              value={searchText}
                              onChange={(event) => {
                                event.stopPropagation();
                                setSearchText(event.target.value);
                              }}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </InputGroup>
                        </div>
                        <div>
                          <ul className="list">
                            {influencers.filter((influencer) => influencer.name.toLowerCase().includes(searchText.toLowerCase())).map((influencer) => (
                              <li
                                key={influencer.id}
                                className="list"
                                onClick={() => handleSelectedInfluencer(influencer.id, influencer.name)}
                              >
                                {influencer.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>



              </HStack>

                  
                  </FormControl>

                     }
                     {/* </div> */}




                </Flex>
                </div> 
                {/* <></> */}
                <Flex gap={5} mb={10}>

                <FormControl isRequired isInvalid={!!errors.email} className="input-Email" >
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Email
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    value={userData.email}
                    onChange={handleUserDataChange}
                    _focus={{ zIndex: "0" }}
                  />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired isInvalid={!!errors.password} className="input-password">
                  <FormLabel
                    fontSize="sm"
                    position="absolute"
                    top="-3"
                    left="3"
                    zIndex="1"
                    background="#ffffff"
                    px="1"
                  >
                    Password
                  </FormLabel>
                  <InputGroup>
                    <Input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      maxLength={10}
                      value={userData.password}
                      onChange={handleUserDataChange}
                      _focus={{ zIndex: "0" }}
                    />
                    
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {showPassword ? <ViewOffIcon></ViewOffIcon> : <ViewIcon></ViewIcon>}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>

              </Flex>

                                              


            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button
              isLoading={isLoading}
              spinner={<PulseLoader size={8} color="white" />}
              loadingText="Creating"
              spinnerPlacement="end"
              colorScheme="green"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
        </Box>
      </Drawer>

      <CampaignDialogBox
        isOpen={isOpenCampaignDialogBox}
        onClose={() =>  setIsOpenCampaignDialogBox(false)}
        onNo={handleNo}
        onYes={handleYes}
      />
    </Box>
  );
};

export default UserFormModal;
