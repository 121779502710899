import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Table, Thead, Tbody, Tr, Th, Td, Select, Button } from '@chakra-ui/react';
import { color } from 'framer-motion';
import { useEffect } from 'react';
import {
  Box,
  Flex, 
  useColorMode,
  useToast,

} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from 'react';
import { MdMarkEmailRead } from 'react-icons/md';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProspectEmailsModel = ({ isOpen, onClose, EmailsData, prospect_id, SaveData,VerifyEmails,hadnleClickEmails,handleVerifyEmails }) => {
  const emails = EmailsData?.Format_emails ?? [];
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token');
  const navigate = useNavigate()

  const { colorMode } = useColorMode();
  const toast = useToast();
  const [selections, setSelections] = useState(null);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [individualEmail, setIndividualEmail] = useState();
  const[editableColum,setEditableColumn]=useState(null)
  const [newArray, setNewArray] = useState([]);


//   console.log("verifyemails pem",VerifyEmails);
//   console.log("EmailsDataa",EmailsData);
// console.log("selectedemails >>>",selectedEmails)

// console.log("emails>>>>",emails)
 
  const handleAllChecked = (e) => {
    //console.log("checked >>>>", emails)
    const checked = e.target.checked;
    if (checked) {
      const allEmails = emails.map(emailData => emailData.email);
      //console.log("all emails>>>>",allEmails)
      setSelectedEmails(allEmails);
    } else {
      setSelectedEmails([]);
    }
  };


  const handleEmptyState =()=>{
    setSelectedEmails([]);
    setIndividualEmail();

  }


  const handleIndividualCheckbox = (status, email, checked) => {
 
    setSelections(status);
  
    setSelectedEmails(prev => {
     
      const updatedSelectedEmails = checked ? [...prev, email] : prev.filter(e => e !== email);
      if (updatedSelectedEmails.length === 1) {
        setIndividualEmail(updatedSelectedEmails[0]);
      } else {
        setIndividualEmail(null);
      }
      return updatedSelectedEmails; 
    });
    
  };
  
//  console.log("seelection>>>",selections)

  const handleMarkMail = async () => {
    const payload = {
      "prospect_id": prospect_id,
      'emails': selectedEmails
    }
    try {
      const response = await axios.post(`${BASE_URL}/verify-email/`, payload,{
      headers: {
        'Authorization': `Bearer ${token}`
      }});
      if (response.status === 200) {
        hadnleClickEmails();
        handleVerifyEmails();
        //console.log("okkk response", response)
        toast({
          title: "User Email verify.",
          description: " User Email verify  successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
        // onClose();
        setSelectedEmails([]);
        setIndividualEmail();
      }
    }
    catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
      };
      console.log("error", error)
    }
  }
// console.log("individyal email>>>>",individualEmail)
  const handleUseEmail = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', `Bearer ${token}`);

    const raw = JSON.stringify({
      "prospect_id": prospect_id,
      "email": individualEmail,
      "email_validity": selections,

    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch(`${BASE_URL}/prospect-update-emails/`, requestOptions)
    .then((response) =>  response.json())
      .then((result) => {
        toast({
          title: "User Email updated.",
          description: "User Email updated Successfully.",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
     
        SaveData();
        setSelections(null);
        onClose();
        setSelectedEmails([]);
        setIndividualEmail();
   
      })
      .catch((error) => {
        console.error(error)
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized: User session expired');
            localStorage.removeItem("userEmail");
            localStorage.removeItem("token");
            navigate('/login');
            return; 
        }
    });
    
  }


  useEffect(() => {
// console.log("use EmailsData >>",EmailsData)
// console.log("use Verify >>",VerifyEmails)



  },[])



useEffect(() => {
  const updatedArray = EmailsData && EmailsData?.Format_emails?.map(secondItem => {
    const matchingItem = VerifyEmails && VerifyEmails.find(firstItem => firstItem.email === secondItem.email);
    if (matchingItem) {
      const createdDate = new Date(matchingItem.created_ts);
      const formattedDate = createdDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\//g, '-');
      const formattedTime = createdDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
      return {
          email: secondItem.email,
          status: matchingItem.status,
          created_ts:  `${formattedDate} ${formattedTime}`,
          percentage : secondItem.percentage
      };
    } else {
      return {
          email: secondItem.email,
          status: "",
          created_ts: "",
          percentage : secondItem.percentage
      };
    }
  });

  setNewArray(updatedArray || []);
}, [EmailsData, VerifyEmails]); // Make sure to add this line



const handleColumnClick =(email,status)=>{
  // console.log("status",status)
  // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",email,status)
  setEditableColumn(email)
  setSelections(status)
  // setSelections()
}


const handleCellBlur = (email, status) => {
  // Update newArray with the new status for the specified email
  
  const updatedArray = newArray.map(item => {
    if (item.email === email) {
      return { ...item, status: status };
     
    }
    return item;
  
  });

  setNewArray(updatedArray); // Update the state with the modified array
  setEditableColumn(null);
   // Reset the editable column
   handleSaveColum(email,status);
  //  console.log("updated arrayyy>>",updatedArray)
  //  console.log("emailstatttatttattatatatatatatt>>",email)
};



const handleSaveColum =async(email,status)=>{

  const payload ={
    "prospect_id":prospect_id,
       "email":email,
       "status":status
  }
  try{
    const response=await axios.post(`${BASE_URL}/update_status_verify_email/`, payload,{
    headers: {
      'Content-Type': 'application/json',
      'Authorization' : `Bearer ${token}`
    }
  });
    // console.log("response>>>",response)
    if (response.status === 401) {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("token");
      navigate('/login');
    };  
    
    if(response.statusText = "OK"){
      toast({
        title: "Update Status Verify Emails.",
        description: "Succesfully Update Status Verify Emails.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }


    
  }
  catch (error){
   console.error("error",error)
  }

}


  return (
    <Modal size={"6xl"} isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={"500"} fontSize={"2xl"}><Flex alignItems="center" justifyContent="space-between">
          <Box fontWeight="500" fontSize="xl">
            Select Email Address
          </Box>
          <Flex justifyContent="flex-end">
            {selectedEmails.length > 0 &&
              <div style={{ textAlign: 'right', margin: "-6px" }}>
                <Button colorScheme="blue" mr={5} size={"sm"} onClick={handleMarkMail}>
                  <RiVerifiedBadgeFill size={25} color="white" />
                </Button>
              </div>
            }
            {selectedEmails.length == 1 && (
              <Button colorScheme="green" mr={7} size={"sm"} onClick={handleUseEmail}
              
                 >
                <MdMarkEmailRead size={25} color="white" />
              </Button>)}
          </Flex>
        </Flex></ModalHeader>
        <ModalCloseButton color='Red' onClick={handleEmptyState} />
        <ModalBody>
          <Box
            fontSize={14}
            mb={1}
            boxShadow="xs"
            rounded={"md"}
            sx={{
              position: 'relative'
            }}
          >
            <Table variant="simple"  >

              <Thead bg={"green.400"}>
                <Tr>
                  {/* <input type='checkbox'
                    onChange={handleAllChecked}
                  ></input> */}
                  <Th color={"#FFFFFF"}>
                    <input
                      type='checkbox'
                      onChange={handleAllChecked}
                      checked={selectedEmails.length === emails.length && emails.length > 0}
                      indeterminate={selectedEmails.length > 0 && selectedEmails.length < emails.length}
                    />
                  </Th>
                  <Th color={"#FFFFFF"}>Emails</Th>
                  <Th color={"#FFFFFF"}>Usage</Th>
                  <Th color={"#FFFFFF"}>Emails Validity</Th>
                  <Th color={"#FFFFFF"}>Verified</Th>
                </Tr>
              </Thead>
              <Tbody>
                {newArray && newArray?.map((value, index) => {
                  return (
                    <Tr _hover={{
                      bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                      boxShadow:
                        "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                    }}>
                      <Td>
                        <input
                          type='checkbox'
                          checked={selectedEmails.includes(value.email)}
                          onChange={(e) => handleIndividualCheckbox(value.status,value.email, e.target.checked)}
                        />
                      </Td>
                      {/* <Td key={index}><input type='checkbox' name={index}  onClick={(e)=> { handleIndividualChechboxs(e, value) }}></input></Td> */}
                      <Td>{value.email}</Td>
                      <Td>{value.percentage}</Td>
                      
                      <Td onClick={()=>handleColumnClick(value.email, value.status)}>
                        {editableColum == value.email ?
                          <textarea
                          value={selections}
                          onChange={(e) => setSelections(e.target.value)}
                          onBlur={()=>handleCellBlur(value.email,selections)}
                          />:value.status}
                      </Td>
                      <Td>{value.created_ts}</Td>
                    </Tr>
                  )
                })}

              </Tbody>
            </Table>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ProspectEmailsModel;
