import { 
    AlertDialog, 
    AlertDialogBody, 
    AlertDialogContent, 
    AlertDialogFooter, 
    AlertDialogHeader, 
    AlertDialogOverlay, 
    Button, 
} from '@chakra-ui/react'
import React from 'react'

const CampaignDialogBox = ({ isOpen, onClose, onNo, onYes }) => {
    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>Create New User</AlertDialogHeader>

                    <AlertDialogBody>
                    Do you want to create a New User?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button colorScheme='yellow' onClick={onNo}>No</Button>
                        <Button colorScheme='red' ml={3} onClick={onYes}>Yes</Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default CampaignDialogBox