import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Divider,
  Box,
  Text, 
  HStack,
  Input, 
  InputLeftElement,
  InputGroup,
  Skeleton,
} from "@chakra-ui/react";
import { MdCheckCircle, MdMailOutline, MdMenu, MdPhone, MdSettings } from "react-icons/md";
import GmailAuth from "../../GmailAuth";
import { useNavigate } from 'react-router-dom';

export const EmailAccounts = () => {

    const [userEmails, setUserEmails] = useState();
    const [isLoading, setIsLoading] = useState(false)

    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL ;
    const token = localStorage.getItem('token')
    const navigate = useNavigate()


    const fetchEmails = async()=>{
        setIsLoading(true)

        try {
            const response = await fetch(BASE_URL + "/get-user-emails/",{
              headers: {
                'Authorization': `Bearer ${token}`
            }});
            
            if (response.status === 401) {
              console.error('Unauthorized: User session expired');
              localStorage.removeItem("userEmail");
              localStorage.removeItem("token");
              navigate('/login');
            };
            if (!response.ok) {
              throw new Error('Network response was not ok');
            };
            const data = await response.json();
            setUserEmails(data.User_Emails)
    
            setIsLoading(false);
            if (data) {
    
            }
    
          } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
            
          }

    }
    useEffect(()=> {
        fetchEmails()        
    },[])

  return (
    <Box px={5} mx={'auto'}>
        <HStack  justifyContent={'space-between'} my={10}>
            <InputGroup w={'30%'}>
                <InputLeftElement pointerEvents='none'>
                <MdPhone color='gray.300' />
                </InputLeftElement>
                <Input type='text' placeholder='search' />
            </InputGroup>
            <GmailAuth payloadvalue={0} fetchEmails={fetchEmails}/>
        </HStack>
        
      <List spacing={3} my={10}>
        {userEmails?
            <Skeleton isLoaded={!isLoading}>
            {userEmails.map((user, index) => {
            return (
                <>
                <ListItem key={index} display={'flex'} justifyContent={'space-between'} my={2} mx={'20px'}>
                <HStack>
                    <ListIcon as={MdMailOutline} color="green.500" />
                    <Text>{user.email_id} ({user.campaign_info})</Text>
                </HStack> 
                <HStack>
                    <ListIcon as={MdSettings} color="green.500" />
                    <ListIcon as={MdMenu} color="green.500" />
                </HStack> 
                </ListItem>
                <Divider/>
                </>
            );
            })}
            </Skeleton> : 
            <Skeleton height={'100px'}/>
        }
        
      </List>
    </Box>
  );
};
