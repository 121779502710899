// sequenceApi.js

import { Toast, createStandaloneToast } from "@chakra-ui/react";


const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL; // Replace with your API's base URL
const token = localStorage.getItem('token');



// Function to fetch all sequences

// Function to fetch all sequences
export const fetchAllSequences = async (token) => {
    const url = `${BASE_URL}/allsequence/`; // Adjust the endpoint URL
    try {
      const response = await fetch(url,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch sequences');
      }
      const data = await response.json();
      return data.Seqeunce;
    } catch (error) {

      throw error;
    }
  }

// Function to fetch a sequence by its ID
// export function fetchSequenceById(sequenceId) {
//   const url = `${API_BASE_URL}/sequences/${sequenceId}`; // Adjust the endpoint URL
//   return fetch(url)
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error('Failed to fetch sequence');
//       }
//       return response.json();
//     });
// }
