import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export const SequenceSetting = ({ sequenceId }) => {
  return (
    <Box h={"calc(100vh - 60px)"}>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        h={"calc(100vh - 60px)"}
      >
        <Text fontSize="5xl">Coming soon !!!</Text>
      </Flex>
    </Box>
  );
};
